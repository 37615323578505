<template>
    <div class="container  my-2">
        <h2>Nozel Readings</h2>

        
    </div>
    

    <div class="container">
        
        <table class="table" v-if="readings.length > 0">
            <thead>
                <tr>
                    <th scope="col">Filling Point ID</th>
                    <th scope="col">Reading ID</th>
                    <th scope="col">Filling Point Type</th>
                    <th scope="col">Last Reading Date</th>
                    <th scope="col">Last Reading</th>
                    <th scope="col">Last Hidden Reading</th>
                    <th scope="col" v-if="this.userStore?.user?.UserType == 'super_admin'">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="r in readings" :key="r.readings">
                    <td>{{ r.FillingPointID }}</td>
                    <td>{{ r.lastReadingID }}</td>
                    <td>{{ r.PointType }}</td>
                    <td>{{ r.lastReadingDate }}</td>
                    <td>{{ r.lastReading }}</td>
                    <td>{{ r.lastHiddenReading }}</td>
                    <td class="btn-group" role="group" v-if="this.userStore?.user?.UserType == 'super_admin'">
                        <button @click="openModal('edit', r)" class="btn btn-primary" >Edit</button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <ModalComponent :showModal="showModal.edit" @close="closeModal('edit')">
        <div v-if="modalType === 'edit'" class="modal-content">
            <h4>Edit Reading</h4>
            <p>Pump: <b>{{ editReading.PumpID }}</b> Point: <b>{{ editReading.PointType }}</b> Point ID: <b>{{ editReading.FillingPointID }}</b></p>
            <form @submit.prevent="onEdit">
                <div class="form-group">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Reading</span>
                        <input type="text" class="form-control" v-model="editReading.lastReading">
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Hidden Reading</span>
                        <input type="text" class="form-control" v-model="editReading.lastHiddenReading">
                    </div>
                </div>
                <button type="submit" class="btn btn-primary mt-2">Update</button>
            </form>
        </div>
    </ModalComponent>
</template>


<script>
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
import { toastMixin } from '@/mixins/toastMixin.js';
import ModalComponent from '@/components/ModalComponent.vue';
export default {
    mixins: [GlobalMixin, toastMixin],
    components: {
        ModalComponent,
    },
    data() {
        return {
            showModal: {
                add:false,
                edit: false
            },
            reading: null,
            readings: [],
            editReading:{}
        };
    },

    mounted() {
        document.title = 'Customers';
        console.log(this.userStore.user);
    },
    async created() {
        this.getReadings();
    },

    methods: {
        openModal(type, e = null) {
            this.modalType = type;
            this.showModal[type] = true;
            if (type === 'edit' && e) {
                console.log(e);
                this.editReading = { ...e };
            }
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        async getReadings(){
            let query = `SELECT fp.*, 
                COALESCE((SELECT r.Quantity 
                            FROM readings r 
                            WHERE r.FillingPointID = fp.FillingPointID AND r.PumpID = fp.PumpID 
                            ORDER BY r.ReadingDate DESC, r.ReadingID DESC 
                            LIMIT 1), 0) AS lastReading,
                COALESCE((SELECT r.HiddenReading 
                            FROM readings r 
                            WHERE r.FillingPointID = fp.FillingPointID AND r.PumpID = fp.PumpID 
                            ORDER BY r.ReadingDate DESC, r.ReadingID DESC 
                            LIMIT 1), 0) AS lastHiddenReading,
                (SELECT r.ReadingDate
                FROM readings r
                WHERE r.FillingPointID = fp.FillingPointID AND r.PumpID = fp.PumpID
                ORDER BY r.ReadingDate DESC, r.ReadingID DESC
                LIMIT 1) AS lastReadingDate,
                (SELECT r.ReadingID
                FROM readings r
                WHERE r.FillingPointID = fp.FillingPointID AND r.PumpID = fp.PumpID
                ORDER BY r.ReadingDate DESC, r.ReadingID DESC
                LIMIT 1) AS lastReadingID
                FROM fillingpoints fp 
                WHERE fp.PumpID = ${this.globalPumpId}`;
            this.readings = await this.getRequest(query);

            
        },
        async onEdit(){
            console.log('wd', this.editReading);
            if (this.editReading.lastReadingID !== null ) {
                let data = {
                    PumpID : this.editReading.PumpID,
                    ReadingDate :  this.editReading.ReadingDate,
                    FillingPointID: this.editReading.FillingPointID,
                    Quantity: this.editReading.lastReading,
                    HiddenReading: this.editReading.lastHiddenReading,
                }
                try {
                    await this.updateRequest('readings', 'ReadingID', this.editReading.lastReadingID, data);
                    this.getReadings();
                    this.successToast();
                    this.closeModal('edit');
                } catch (error) {
                    console.error('Error updating', error);
                }
            }else{
                const currentDate = new Date().toISOString().split('T')[0];
                    const data = {
                    tableName: 'readings',
                    data : {
                        PumpID : this.editReading.PumpID,
                        ReadingDate : currentDate,
                        FillingPointID: this.editReading.FillingPointID,
                        Quantity: this.editReading.lastReading,
                        HiddenReading: this.editReading.lastHiddenReading,
                    }
                };

                this.axios.post(`${this.API_URL}insert.php`, data)
                .then(() => {
                    this.successToast();
                    this.getReadings();
                    this.closeModal('edit')
                })
                .catch(error => {
                    console.error(error);
                });
            }

        }
     
        
    }
}
</script>