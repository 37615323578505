<template>
  <div class="c-modal-overlay" v-if="showModal">
    <div class="c-modal">
      <span class="close" @click="closeModal()">X</span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {

    };
  },
  methods: {
    closeModal() {
      this.$emit('close'); // Emitting the 'close' event when the modal is closed
    }
  }
};
</script>

<style>
/* Modal styles */
.c-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.c-modal {
  position: relative;
  width: 80%;
  /* height: 40%; */
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 100px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
