<template>
    <div class="container  ">
        <div class="row align-items-center my-3">
            <div class="col-lg-6">
                <h2>Inventory</h2>
            </div>
            <div class="col-lg-6 text-right">
                <button class="btn btn-primary" @click="openModal('add')">Add Stock</button>
            </div>
        </div>

        <div class="container">
            <table class="table" v-if="lastQty != undefined">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Diesel Stock</th>
                        <th scope="col">Petrol Stock</th>
                        <th scope="col" v-if="this.userStore?.user?.UserType == 'super_admin'">Actions</th>
                    </tr>
                </thead> 
                <tbody>
                    <tr>
                        <th scope="row">{{ lastQty.Date }}</th>
                        <td>{{ lastQty.ClosingDieselQty }}</td>
                        <td>{{ lastQty.ClosingPetrolQty }}</td>
                        
                        <td class="btn-group" role="group" v-if="this.userStore?.user?.UserType == 'super_admin'">
                            <button @click="openModal('edit', lastQty)" class="btn btn-primary" >Edit</button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>

    </div>

    <Modal :showModal="showModal.add" @close="closeModal('add')">
        <div v-if="modalType === 'add'" class="modal-content">
            <h2>Add Received Stock</h2>
            <form @submit.prevent="onSubmit">
                <div class="col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Date</span>
                        <input type="date" class="form-control" v-model="selectDate" required>
                    </div>
                </div>
                <div class="form-group">
                    <label for="petrol">Received Petrol Qty</label>
                    <input type="number" class="form-control" id="petrol" v-model="ReceivedPetrolQty" step="any">
                </div>
                <div class="form-group">
                    <label for="diesel">Received Diesel Qty</label>
                    <input type="number" class="form-control" id="diesel" v-model="ReceivedDieselQty" step="any">
                </div>
                <button type="submit" class="btn btn-primary mt-2" >Submit</button>
            </form>
        </div>
    </Modal>
    <Modal :showModal="showModal.edit" @close="closeModal('edit')">
        <div v-if="modalType === 'edit'" class="modal-content">
            <h2>Add Received Stock</h2>
            <form @submit.prevent="onEdit">
                <div class="col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Date</span>
                        <input type="date" class="form-control" v-model="editInventory.Date" required>
                    </div>
                </div>
                <div class="form-group">
                    <label for="petrol">Received Petrol Qty</label>
                    <input type="number" class="form-control" id="petrol" v-model="editInventory.ClosingPetrolQty" step="any">
                </div>
                <div class="form-group">
                    <label for="diesel">Received Diesel Qty</label>
                    <input type="number" class="form-control" id="diesel" v-model="editInventory.ClosingDieselQty" step="any">
                </div>
                <button type="submit" class="btn btn-primary mt-2" >Submit</button>
            </form>
        </div>
    </Modal>
</template>
<script>
import Modal from '@/components/ModalComponent.vue';
import { InventoryMixin } from '@/mixins/InventoryMixin.js';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
export default {
    mixins: [ GlobalMixin, InventoryMixin],
    components: {
        Modal,
    },
    data() {
        return {
            ReceivedPetrolQty: 0,
            ReceivedDieselQty: 0,
            pumpId: null,
            selectDate:null,
            getInventoryStock:[],
            showModal:{
                add:false,
                edit:false
            },
            editInventory:{},
            lastQty:{},
        };
    },
    async mounted() {
        document.title = 'Inventory';
        this.getLastQty();
    },
    methods: {
        openModal(type, e = null) {
            this.modalType = type;
            this.showModal[type] = true;
            if (type === 'edit' && e) {
                console.log(e);
                this.editInventory = { ...e };
            }
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        async onSubmit() {
            
            let data = {
                Date: this.selectDate,
                PumpID: this.globalPumpId,
                ReceivedPetrolQty: this.ReceivedPetrolQty,
                ReceivedDieselQty: this.ReceivedDieselQty,
                ClosingPetrolQty: parseFloat(this.ReceivedPetrolQty) + parseFloat(this.lastQty.ClosingPetrolQty),
                ClosingDieselQty: parseFloat(this.ReceivedDieselQty) + parseFloat(this.lastQty.ClosingDieselQty),
            }
            this.submitInventoryData(data);
        },
        async getLastQty(){
            // this.lastQty = await this.lastEntryInventoryData(this.globalPumpId);
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT * FROM inventory WHERE PumpID = ${this.globalPumpId} ORDER BY ID DESC LIMIT 1;`,
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                });
                
                this.lastQty = response.data[0]
                if (this.lastQty == undefined) {
                    console.log("is undefind");

                    this.lastQty ={
                        Date:0,
                        ClosingPetrolQty: 0,
                        ClosingDieselQty: 0
                    }
                }
                // return {
                // }
            } catch (error) {
                // console.error('Error fetching Pump Data', error);
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.ClosingPetrolQty = 0;
                this.ClosingDieselQty = 0;
            }
            console.log(this.lastQty.ClosingPetrolQty);
        },
        async onEdit() {
            
            let data = {
                Date: this.editInventory.selectDate,
                PumpID: this.globalPumpId,
                ReceivedPetrolQty: this.editInventory.ReceivedPetrolQty,
                ReceivedDieselQty: this.editInventory.ReceivedDieselQty,
                ClosingPetrolQty: this.editInventory.ClosingPetrolQty,
                ClosingDieselQty: this.editInventory.ClosingDieselQty,
            };
            try {
                await this.updateRequest('inventory', 'ID', this.editInventory.ID, data);
                this.getLastQty();
                this.successToast();
                this.closeModal('edit');
            } catch (error) {
                console.error('Error updating Inverntory:', error);
            }
        },
    }
  
}
</script>