import { toast } from 'vue3-toastify';

export const toastMixin = {
  methods: {
    successToast() {
      toast.success('Data Added Successfully!', {
        autoClose: 2000,
      });
    },
    deleteToast() {
      toast.warning('Data Deleted!', {
        autoClose: 2000,
      });
    },
    failedToast() {
      toast.error('Something Wrong!', {
        autoClose: 2000,
      });
    }
  }
};
