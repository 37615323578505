<template>
   
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-10 p-2">
                <h2> Employees History</h2>
            </div>
        </div>
        
        <div class="row hprint">
            <div class="col-lg-6">
                <button @click="exportToPDF()" class="btn btn-danger ml-2">PDF</button>
            </div>
            <div class="row col-lg-4">
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="minDate">
                </div>
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="maxDate">
                </div>
            </div>
            <div class="col-lg-2 text-end">
                <button @click="dateSelected()" class="btn btn-secondary">Show Data</button>
            </div>
        </div>
    </div>

    <div class="container">
        <table class="table" id="myTable" v-if="records.length > 0">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Name</th>

                    <th scope="col">Phone</th>
                    <th scope="col">Amount Advance</th>
                    <th scope="col">Salary</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="employee in records" :key="employee.EmployeeID">
                    <th scope="row">{{ employee.Date }}</th>
                    <td>{{ employee.Name }}</td>
                    <td>{{ employee.PhoneNumber }}</td>
                    <td>{{ employee.Amount }}</td>
                    <td>{{ employee.Salary }}</td>
                </tr>

            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ total }}</td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>
<script>
import { toastMixin } from '@/mixins/toastMixin.js';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
export default {
    mixins: [toastMixin, GlobalMixin],
    data() {
        return {
            records: [],
            PumpId: null,
            EmployeeID: null,
            minDate: null,
            maxDate: null,
        };
    },
    mounted() {
        document.title = 'Customers';
    },
    async created() {
        this.EmployeeID = this.$route.params.id;
        console.log(this.EmployeeID);
        // this.getAllPumps();

        try {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT *
                        FROM employees
                        LEFT JOIN wages ON employees.EmployeeID  = wages.EmployeeID 
                        WHERE employees.EmployeeID  = ${this.EmployeeID} ORDER BY DATE(wages.Date);
                    `,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            console.log(response.data);
            this.records = response.data;
        } catch (error) {
            if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
        }
    },
    methods: {
        formatDate(dateString) {
            const formattedDate = new Date(dateString).toLocaleDateString('en-GB');
            return formattedDate;
        },
        async dateSelected() {
            const minDate = `${this.minDate}`;
            const maxDate = `${this.maxDate}`;
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT *
                        FROM employees
                        LEFT JOIN wages ON employees.EmployeeID  = wages.EmployeeID 
                        WHERE employees.EmployeeID  = ${this.EmployeeID} AND wages.Date >= '${minDate}' AND wages.Date <= '${maxDate}' ORDER BY DATE(wages.Date);`
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.records = response.data;

            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            }
        },
    },
    computed: {
        total() {
            let total = 0;
            this.records.forEach(record => {
                total += parseFloat(record.Amount);
            });
            return total;
        },

    }
}

</script>