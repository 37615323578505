<template>
    <div class="container mt-5 ">
        <h3 class="mb-3">Update Stock</h3>
        <form class="row" @submit.prevent="onSubmit">

            <div class="col-lg-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="inputGroupSelect01">Product</label>
                    </div>
                    <select class="form-control" id="customer" v-model="product" @change="updateRecord()">
                        <option v-for="product in allProducts" :key="product.ProductId" :value="product.ProductId">
                            {{ product.name }} - {{ product.variant }} - {{ product.company }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Date</span>
                    <input type="date" class="form-control" v-model="selectDate">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="inputGroupSelect01">Pump</label>
                    </div>
                    <select class="form-control" id="pump" v-model="pump" >
                        <option v-for="pump in allPumps" :key="pump.PumpID" :value="pump.PumpID">
                            {{ pump.PumpName }}
                        </option>
                    </select>
                </div>
            </div>


            <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Received Qty</span>
                    <input type="number" class="form-control" v-model="receivedQty">
                </div>
            </div>

            <!-- <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Sold Qty</span>
                    <input type="number" class="form-control" v-model="soldQty" step="any">
                </div>
            </div> -->



            <button type="submit" class="btn btn-primary mt-2">Save</button>
        </form>
    </div>
    <!-- <div class="container mt-5 ">
        <div class="container">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Received Qty</th>
                        <th scope="col">Sold Qty</th>
                        <th scope="col">In Stock</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="p in records" :key="p.EntryID">
                        <td>{{ p.Date }}</td>
                        <td>{{ p.name }} {{ p.company }} {{ p.variant }}</td>
                        <td>{{ p.ReceivedQty }}</td>
                        <td>{{ p.SoldQty }}</td>
                        <td>{{ p.InStock }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div> -->
</template>
<script>
import { toastMixin } from '@/mixins/toastMixin.js';
export default {
    mixins: [toastMixin],
    data() {
        return {
            name: null,
            company: null,
            soldQty: null,
            receivedQty: null,
            selectDate: null,
            price: 0,
            allProducts: [],
            product: null,
            pump: null,
            allPumps: [],
            records:[],
        };
    },
    created() {
        this.getAllPumps();
        this.getProducts();
    },
    mounted() {
        document.title = 'Add Mobil Oil';
    },
    methods: {
        async updateRecord() {
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT *
                        FROM mobiloils
                        LEFT JOIN mobiloilstock ON mobiloils.ProductId  = mobiloilstock.ProductId 
                        WHERE mobiloils.ProductId  = ${this.product} AND mobiloils.PumpID = ${this.pump}  ORDER BY DATE(mobiloilstock.Date);`
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                console.log(response.data);
                this.records = response.data;
                
            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            }

        },
        async onSubmit() {

            // console.log(this.product);
            let inStock = await this.InStock(this.pump, this.product);
            console.log(inStock.InStock);
            const data = {
                tableName: 'mobiloilstock',
                data: {
                    Date: this.selectDate,
                    ProductId: this.product,
                    ReceivedQty: this.receivedQty,
                    SoldQty: 0,
                    InStock: parseFloat(inStock.InStock) + parseFloat(this.receivedQty),
                    PumpID: this.pump,
                },
            };
            this.axios.post(`${this.API_URL}insert.php`, data)
                .then(() => {
                    this.successToast();
                    this.$router.push('/mobil-oil');
                })
                .catch(error => {
                    console.error(error);
                });
        },
        async InStock(pumpId, productId) {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM mobiloilstock WHERE PumpID = ${pumpId} AND ProductId = ${productId} ORDER BY Date DESC LIMIT 1;`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (response?.data[0]?.InStock) {
                return {
                    InStock: response.data[0].InStock,
                }
            } else {
                return {
                    InStock: 0,
                }
            }
        },
        async getAllPumps() {
            this.axios.get(`${this.API_URL}get_table_data.php`, {
                params: {
                    tableName: 'petrolpumps'
                }
            }).then((response) => {
                this.allPumps = response.data;
            }).catch((error) => {
                console.error('Error fetching Petrol Pumps:', error);
            });
        },
        async getProducts() {
            this.axios.get(`${this.API_URL}get_table_data.php`, {
                params: {
                    tableName: 'mobiloils'
                }
            }).then((response) => {
                this.allProducts = response.data;
            }).catch((error) => {
                console.error('Error fetching Petrol Pumps:', error);
            });
        },
        formatDate(dateString) {
            const formattedDate = new Date(dateString).toLocaleDateString('en-GB');
            return formattedDate;
        },

    }
}
</script>