<template>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-10 p-2">
                <h2> Cards Transactions</h2>
            </div>
            <div class="col-lg-2 text-right">
                <button @click="openModal('transferTo')" class="btn btn-primary">Transfer To</button>
            </div>
        </div>
        <div>
            <h5>PSO Cards Amount: {{ PSOtransactionsTotal }}</h5>
        </div>
        <div>
            <h5>Other Cards Amount : {{ OthertransactionsTotal }}</h5>
        </div>
    
        <div class="row hprint">
            <div class="col-lg-6">
                <button @click="exportToPDF()" class="btn btn-danger ml-2">PDF</button>
            </div>
            <div class="row col-lg-4">
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="minDate">
                </div>
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="maxDate">
                </div>
            </div>
            <div class="col-lg-2 text-end">
                <button @click="dateSelected()" class="btn btn-secondary">Show Data</button>
            </div>
        </div>

        <table class="table" id="myTable">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Card Number</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Transferred Amount</th>
                    <th scope="col">Transferred Account</th>
                    <th scope="col">CardType</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="transaction in transactions" :key="transaction.transactionId">
                    <td scope="row">{{ formatDate(transaction.Date) }}</td>
                    <td>{{ transaction.CardNumber }}</td>
                    <td>{{ transaction.Amount }}</td>
                    <td>{{ transaction.transferredAmount }}</td>
                    <td>{{ transaction.transferredAccount }}</td>
                    <td>{{ transaction.CardType }}</td>

                </tr>

            </tbody>
        </table>
    </div>

    <ModalComponent :showModal="showModal.transferTo" @close="closeModal('transferTo')">
        <h4>Add Card Transactions</h4>

        <div class="input-group mb-1">
            <span class="input-group-text">Transfer Amount</span>
            <input type="number" class="form-control" v-model="transferAmount">
        </div>
        <div class="input-group mb-1">
            <span class="input-group-text">Transfer Account Number</span>
            <input type="number" class="form-control" v-model="accountNumber">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">Card Type</label>
            </div>
            <select class="form-control" id="customer" v-model="CardType">
                <option value="PSO">PSO</option>
                <option value="other">Credit/Debit</option>
            </select>
        </div>
        <button @click="saveTrasfers()" class="btn btn-primary mt-3">Transfer Now</button>

        <!-- <div class="row">
            <table class="table">
                <thead>
                    <tr>
                        <th>Card Number</th>
                        <th>Amount</th>
                        <th>Card Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="transaction in cardTransactions" :key="transaction.CardNumber">
                        <td>{{ transaction.CardNumber }}</td>
                        <td>{{ transaction.CardAmount }}</td>
                        <td>{{ transaction.CardType }}</td>
                    </tr>
                </tbody>
            </table>
        </div> -->
    </ModalComponent>
</template>

<script>
import ModalComponent from '@/components/ModalComponent.vue';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
export default {
    mixins: [ GlobalMixin],
    components: {
        ModalComponent,
    },
    data() {
        return {
            showModal: {
                transferTo: false,
            },
            minDate: null,
            maxDate: null,
            transferAmount: null,
            accountNumber: null,
            CardType: null,

            transactions: [],
            // PSOtransactionsTotal: 0,
            // OthertransactionsTotal: 0,
        };
    },
    created() {
        this.pumpId = this.$route.params.id;
        this.getTransactions()
    },

    methods: {
        formatDate(dateString) {
            const formattedDate = new Date(dateString).toLocaleDateString('en-GB');
            return formattedDate;
        },
        openModal(type) {
            this.modalType = type;
            this.showModal[type] = true;
        },
        closeModal(type) {
            this.showModal[type] = false;
        },

        async getTransactions() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM cardtransactions WHERE PumpID = ${this.pumpId}`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });

            console.log(response.data);
            this.transactions = response.data;
        },
        saveTrasfers() {
            const data = {
                tableName: 'cardtransactions',
                data: {
                    PumpID: this.pumpId,
                    CardNumber: 0,
                    Amount: 0,
                    CardType: this.CardType,
                    transferredAmount: this.transferAmount,
                    transferredAccount: this.accountNumber,
                },
            };
            console.log(data);
            this.axios.post(`${this.API_URL}insert.php`, data).then(() => {
                this.getTransactions();
                this.closeModal('transferTo');
            }).catch((error) => {
                console.error(error);
            });
        },
        async dateSelected() {
            const minDate = `${this.minDate}`;
            const maxDate = `${this.maxDate}`;
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT * FROM cardtransactions WHERE PumpID = ${this.pumpId}  AND Date >= '${minDate}' AND Date <= '${maxDate}' ;`
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.transactions = response.data;

            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            }
        },



    },
    computed: {
        PSOtransactionsTotal() {
            let t = 0;
            if (this.transactions.length > 0) {
                this.transactions.forEach(e => {
                    if (e.CardType === 'PSO') {
                        t += parseFloat(e.Amount) - parseFloat(e.transferredAmount);
                    }
                });
            }

            return t;
        },
        OthertransactionsTotal() {
            let t = 0;
            if (this.transactions.length > 0) {
                this.transactions.forEach(e => {
                    if (e.CardType === 'other') {
                        t += parseFloat(e.Amount) - parseFloat(e.transferredAmount);
                    }
                });
            }
            
            return t;
        },
    }
};


</script>
<style>
.text-right {
    text-align: right;
}
</style>