<template>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-10 p-2">
                <h2> Customer History</h2>
            </div>
            <div class="col-lg-2 text-right">
                <button @click="openModal('addRecord')" class="btn btn-primary">Add Record</button>
            </div>
        </div>

    </div>
    
    <div class="container">
        <div class="row hprint ">
            <div class="col-lg-6">
                <button @click="exportToPDF()" class="btn btn-danger ml-2">PDF</button>
            </div>
            <div class="row col-lg-4">
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="minDate">
                </div>
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="maxDate">
                </div>
            </div>
            <div class="col-lg-2 text-right">
                <button @click="dateSelected()" class="btn btn-secondary">Show Data</button>
            </div>
        </div>
    </div>
    <div class="container">
        <table class="table" id="myTable">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Received Amount</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">ClosingBalance</th>
                    <th scope="col">Comment</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="r in records" :key="r.CustomerId">

                    <td>{{ r.Date }}</td>
                    <td>{{ r.CustomerName }}</td>
                    <td>{{ r.ReceivedAmount }}</td>
                    <td>{{ r.TotalAmount }}</td>
                    <td>{{ r.ClosingBalance }}</td>
                    <td>{{ r.transactionMsg }}</td>

                </tr>

            </tbody>
        </table>
    </div>

    <ModalComponent :showModal="showModal.addRecord" @close="closeModal('addRecord')">
        <div v-if="modalType === 'addRecord'" class="modal-content">
            <h4>Add Credits</h4>
            <div class="input-group mb-1">
                <span class="input-group-text">Total Amount</span>
                <input type="number" class="form-control" v-model="totalAmount">
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Received</span>
                <input type="number" class="form-control" v-model="cashReceived">
            </div>

            <div class="input-group mb-1">
                <span class="input-group-text">Comment:</span>
                <textarea name="" class="form-control" v-model="transactionMsg" id="" cols="30" rows="5"></textarea>
            </div>
            <div class="col text-right">
                <div class="input-group">
                    <span class="input-group-text">Date</span>
                    <input type="date" class="form-control" name="" id="" v-model="formDate">
                </div>
            </div>

            <button @click="saveData()" class="btn btn-primary my-2">Add Transaction</button>
        </div>
    </ModalComponent>
</template>
<script>
// import { userStore } from "./../../store/UserStore";
import { toastMixin } from '@/mixins/toastMixin.js';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
import ModalComponent from '@/components/ModalComponent.vue';
export default {
    components: {
        ModalComponent,
    },

    mixins: [toastMixin, GlobalMixin],
    data() {
        return {
            showModal: {
                addRecord: false,
            },
            formDate: null,
            records: [],
            PumpId: null,
            CustomerId: null,
            minDate: null,
            maxDate: null,
            totalAmount: 0,
            cashReceived: 0,
            transactionMsg: null,
            transactions: [],
            cashCredit: 0,
        };
    },
    mounted() {
        // console.log('userData', userStore().user.UserID);
        document.title = 'Customers';
        
        this.CustomerId = this.$route.params.id;
        this.getCustomerData();
        
    },
    
    methods: {
        openModal(type) {
            this.modalType = type;
            this.showModal[type] = true;
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        async getCustomerData(){
            if (!this.validateToken()) {
                return; // Token is not valid
            }
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    params: {
                        query: `SELECT customers.CustomerId , customers.CustomerName, customers.PumpID, transactionhistory.CustomerId, transactionhistory.ReceivedAmount, transactionhistory.TotalAmount, transactionhistory.ClosingBalance, transactionhistory.Date, transactionhistory.transactionMsg, transactionhistory.ClosingBalance FROM customers
                        INNER JOIN transactionhistory ON customers.CustomerId = transactionhistory.CustomerId
                        WHERE customers.CustomerId = ${this.CustomerId} ORDER BY DATE(transactionhistory.Date);`,
                    }
                });
                console.log(response.data);
                this.records = response.data;
            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            }
        },
        async dateSelected() {
            const minDate = `${this.minDate}`;
            const maxDate = `${this.maxDate}`;
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT customers.CustomerId , customers.CustomerName, customers.PumpID, transactionhistory.CustomerId, transactionhistory.ReceivedAmount, transactionhistory.TotalAmount, transactionhistory.ClosingBalance, transactionhistory.Date, transactionhistory.ClosingBalance FROM customers
                    INNER JOIN transactionhistory ON customers.CustomerId = transactionhistory.CustomerId
                    WHERE customers.CustomerId = ${this.CustomerId} AND Date >= '${minDate}' AND Date <= '${maxDate}' ORDER BY DATE(transactionhistory.Date);`
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.records = response.data;

            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            }
        },
        async saveData() {
            let closingBalance = await this.getClosingBalance(this.CustomerId);

            let cb = (parseFloat(closingBalance.ClosingBalance) + parseFloat(this.totalAmount)) - parseFloat(this.cashReceived);
            let data = {
                CustomerId: this.CustomerId,
                ReceivedAmount: this.cashReceived,
                transactionMsg: this.transactionMsg,
                TotalAmount: this.totalAmount,
                ClosingBalance: cb,
                Date: this.formDate,
            }
            // console.log(data);
            const apiUrl = `${this.API_URL}insert.php`;
            const requestData = {
                tableName: 'transactionhistory',
                data: data
            };
            this.axios.post(apiUrl, requestData).then(() => {
                this.successToast();
                this.closeModal('addRecord');
                // window.location.reload();
            }).catch((error) => {
                console.error(error);
            });
        },
        async getClosingBalance(CustomerId) {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM transactionhistory WHERE CustomerId = ${CustomerId} ORDER BY Date DESC LIMIT 1; `,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response?.data[0]?.ClosingBalance) {
                return {
                    ClosingBalance: response.data[0].ClosingBalance,
                }
            } else {
                return {
                    ClosingBalance: 0,
                }
            }
        },
    },
    computed: {
        
    }
}

</script>