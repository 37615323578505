<template>
    <div class="container">
        <section>
            <div class="row align-items-center my-2">
                <div class="col">
                    <p class="m-0 py-2">Petrol Price: {{ petrolPriceToday }} - Diesel Price: {{ dieselPriceToday }}</p>

                </div>
                <div class="col">
                    <p class="m-0 py-2">Petrol Quantity: {{ closingPetrolQty }} - Diesel Quantity: {{ closingDieselQty }}
                    </p>
                </div>
                <div class="col text-right">
                    <div class="input-group">
                        <span class="input-group-text">Date</span>
                        <input type="date" class="form-control" name="" id="" v-model="formDate" @change="ThisDate()">
                    </div>
                </div>

            </div>
            <h2 class="text-left mb-3 bg-secondary text-white rounded p-2">{{ pumpDetail.PumpName }} - {{
                pumpDetail.Location
            }}</h2>

            <div class="row">
                <div class="col-lg-5">
                    <h4>Petrol Points</h4>
                    <form class="form-group" @submit.prevent="onSubmit">
                        <div v-for="point in petrolPoints" :key="point.FillingPointID" class="mb-3">
                            <p class="text-dark p-0 m-0">Point Id: {{ point.FillingPointID }}</p>
                            <b>Previous Reading: {{ point.lastReading }}</b>
                            <div class="input-group mb-1">
                                <span class="input-group-text">Today Reading:</span>
                                <input type="number" aria-label="First name" class="form-control" v-model="point.Reading"
                                    @keyup="updatePetrolPointReading(point.FillingPointID, point.Reading)">
                            </div>
                            <b>Previous Hidden Reading: {{ point.lastHiddenReading }}</b>
                            <div class="input-group mb-1">
                                <span class="input-group-text">Hidden Reading:</span>
                                <input type="number" aria-label="First name" class="form-control"
                                    v-model="point.HiddenReading">
                            </div>
                            <b>Sold Today: {{ point.TodayCount }}</b>
                            <hr>
                        </div>
                    </form>
                </div>

                <div class="col-lg-5">
                    <h4>Diesel Points</h4>
                    <form class="form-group" @submit.prevent="onSubmit">
                        <div v-for="point in dieselPoints" :key="point.FillingPointID" class="mb-3">
                            <p class="text-dark p-0 m-0">Point Id: {{ point.FillingPointID }}</p>
                            <b>Previous Reading: {{ point.lastReading }}</b>
                            <div class="input-group mb-1">
                                <span class="input-group-text">Today Reading:</span>
                                <input type="number" step="any" aria-label="First name" class="form-control"
                                    v-model="point.Reading"
                                    @keyup="updateDieselPointReading(point.FillingPointID, point.Reading)">
                            </div>
                            <b>Previous Hidden Reading: {{ point.lastHiddenReading }}</b>
                            <div class="input-group mb-1">
                                <span class="input-group-text">Hidden Reading:</span>
                                <input type="number" aria-label="First name" class="form-control"
                                    v-model="point.HiddenReading">
                            </div>
                            <b>Sold Today: {{ point.TodayCount }}</b>
                            <hr>
                        </div>
                    </form>
                </div>

                <div class="col-lg-2 bg-secondary p-3 rounded-3 text-white">
                    <div class="row">
                        <div class="col mb-3">
                            <h5 class="m-0">Petrol Readings:</h5>
                            <p class="m-0">Readings: {{ PetrolSold }}</p>
                            <p class="m-0">Price: {{ PetrolSold * petrolPriceToday }}</p>
                        </div>

                        <div class="col-12 mb-3">
                            <h5 class="m-0">Diesel Readings:</h5>
                            <p class="m-0">Readings: {{ DieselSold }}</p>
                            <p class="m-0">Price: {{ DieselSold * dieselPriceToday }}</p>
                        </div>

                        <div class="col-12 mb-3">
                            <h5 class="m-0">Mobil Oil Sale:</h5>
                            <p class="m-0">Price: {{ sumMobilOilSales }}</p>
                        </div>


                    </div>
                </div>
            </div>
            <hr>
        </section>

        <div class="row">

            <div class="col-lg-5">
                <div class="row mb-2">
                    <div class="col">
                        <h4>Credit</h4>
                    </div>
                    <div class="col text-right">
                        <button @click="openModal('add')" class="btn btn-primary">Add Credit</button>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <h4>Mobil Oil Sales</h4>
                    </div>
                    <div class="col text-right">
                        <button @click="openModal('mobil')" class="btn btn-primary">Add Sale</button>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="row mb-2">
                    <div class="col">
                        <h4>Employee Wage</h4>
                    </div>
                    <div class="col text-right">
                        <button @click="openModal('employeeExpense')" class="btn btn-primary">Add Wage</button>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <h4>Card Transactions</h4>
                    </div>
                    <div class="col text-right">
                        <button @click="openModal('cardTransactions')" class="btn btn-primary">Add Transactions</button>
                    </div>
                </div>
                <form class="form-group" @submit.prevent="onSubmit">
                    <!-- <div class="input-group mb-1">
                        <span class="input-group-text">Staff Expense</span>
                        <input type="number" aria-label="First name" v-model.number="StaffExpense" class="form-control">
                    </div> -->
                    <div class="input-group mb-1">
                        <span class="input-group-text">Daily Expense</span>
                        <input type="number" aria-label="First name" v-model.number="DailyExpense" class="form-control">
                    </div>
                    <div class="input-group mb-1">
                        <span class="input-group-text">Expense Details</span>
                        <input type="text" aria-label="First name" v-model="ExpenseDetail" class="form-control">
                    </div>
                    <div class="input-group mb-1">
                        <span class="input-group-text">Pump Rent</span>
                        <input type="number" aria-label="Pump Rent" v-model.number="pumpRent" class="form-control">
                    </div>
                    <div class="input-group mb-1">
                        <span class="input-group-text">Bank Deposit</span>
                        <input type="number" aria-label="First name" v-model.number="BankDepositAmount"
                            class="form-control">
                    </div>
                    <div class="input-group mb-1">
                        <span class="input-group-text">Account Number</span>
                        <input type="number" aria-label="First name" v-model.number="BankDepositAccount"
                            class="form-control">
                    </div>
                    <div class="input-group mb-1">
                        <span class="input-group-text">Transfer Tank Diesel</span>
                        <input type="number" aria-label="First name" v-model.number="TTDiesel" class="form-control">
                    </div>
                    <div class="input-group mb-1">
                        <span class="input-group-text">Transfer Tank Petrol</span>
                        <input type="number" aria-label="First name" v-model.number="TTPetrol" class="form-control">
                    </div>

                </form>
            </div>
            <div class="col-lg-2 bg-secondary p-3 rounded-3 text-white">
                <div class="row">
                    <h6>Subtotal: {{ totalSales }}</h6>
                    <h6>Expenses: {{ calTotalExpenses }}</h6>
                    <h6>Credit: {{ sumTransactions }}</h6>
                    <h6>Received: {{ sumReceived }}</h6>
                    <h6>Cards: {{ sumCardTransactions }}</h6>
                    <h6>Tank Transfer: {{ TTAmount }}</h6>
                    <h6>Bank Deposit: {{ BankDepositAmount }}</h6>

                    <h6>Cash In Hand: {{ CashInHand }}</h6>
                    <h6>Total Cash: {{ allTotalCash }}</h6>
                </div>
            </div>
        </div>

        <button class="btn btn-primary" @click="submitData()">Save Report</button>

    </div>


    <ModalComponent :showModal="showModal.add" @close="closeModal('add')">
        <div v-if="modalType === 'add'" class="modal-content">
            <h4>Add Credits</h4>
            <div class="input-group mb-1">
                <span class="input-group-text">Total Amount</span>
                <input type="number" class="form-control" v-model="totalAmount">
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Received</span>
                <input type="number" class="form-control" v-model="cashReceived">
            </div>

            <div class="input-group mb-1">
                <span class="input-group-text">Comment:</span>
                <textarea name="" class="form-control" v-model="transactionMsg" id="" cols="30" rows="5"></textarea>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">Customer</label>
                </div>
                <select class="form-control" id="customer" v-model="customer">
                    <option v-for="customer in customers" :key="customer.CustomerId" :value="customer.CustomerId">
                        {{ customer.CustomerName }}
                    </option>
                </select>
            </div>
            <button @click="addTransaction" class="btn btn-primary my-2">Add Transaction</button>

            <div class="row">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Total Amount</th>
                            <th>Received Amount</th>
                            <th>Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="transaction in transactions" :key="transaction.id">
                            <td>{{ transaction.customer }}</td>
                            <td>{{ getCustomerName(transaction.customer) }}</td>
                            <td>{{ transaction.totalAmount }}</td>
                            <td>{{ transaction.cashReceived }}</td>
                            <td>{{ transaction.totalAmount - transaction.cashReceived }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ModalComponent>
    <ModalComponent :showModal="showModal.mobil" @close="closeModal('mobil')">
        <div v-if="modalType === 'mobil'" class="modal-content">
            <h4>Add Sale</h4>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">Product</label>
                </div>
                <select class="form-control" id="customer" v-model="productName">
                    <option v-for="product in mobilOilProducts" :key="product.ProductId" :value="product.ProductId">
                        {{ product.name }} - {{ product.variant }} - {{ product.company }}
                    </option>
                </select>
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Quantity</span>
                <input type="number" class="form-control" v-model="productQty">
            </div>
            <button @click="addSale" class="btn btn-primary mt-3">Add Transaction</button>

            <div class="row">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="sale in sales" :key="sale.price">
                            <td>{{ sale.productId }}</td>
                            <td>{{ sale.price }}</td>
                            <td>{{ sale.qty }}</td>
                            <td>{{ sale.totalPrice }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </ModalComponent>
    <ModalComponent :showModal="showModal.employeeExpense" @close="closeModal('employeeExpense')">
        <div v-if="modalType === 'employeeExpense'" class="modal-content">
            <h4>Add Sale</h4>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">Employee</label>
                </div>
                <select class="form-control" id="customer" v-model="employeeID">
                    <option v-for="employee in employees" :key="employee.EmployeeID" :value="employee.EmployeeID">
                        {{ employee.Name }}
                    </option>
                </select>
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Amount</span>
                <input type="number" class="form-control" v-model="employeAmount">
            </div>
            <button @click="addEmployeeWage()" class="btn btn-primary mt-3">Add Wage</button>

            <div class="row">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="emplOyeeWage in emplOyeeWages" :key="emplOyeeWage.employeeID">
                            <td>{{ emplOyeeWage.EmployeeID }}</td>
                            <td>{{ emplOyeeWage.Amount }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </ModalComponent>

    <ModalComponent :showModal="showModal.cardTransactions" @close="closeModal('cardTransactions')">
        <h4>Add Card Transactions</h4>

        <div class="input-group mb-1">
            <span class="input-group-text">Card Number</span>
            <input type="number" class="form-control" v-model="CardNumber">
        </div>
        <div class="input-group mb-1">
            <span class="input-group-text">Card Amount</span>
            <input type="number" class="form-control" v-model="CardAmount">
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">Card Type</label>
            </div>
            <select class="form-control" id="customer" v-model="CardType">
                <option value="PSO">PSO</option>
                <option value="other">Credit/Debit</option>
            </select>
        </div>
        <button @click="addCardTransaction()" class="btn btn-primary mt-3">Add Transaction</button>

        <div class="row">
            <table class="table">
                <thead>
                    <tr>
                        <th>Card Number</th>
                        <th>Amount</th>
                        <th>Card Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="transaction in cardTransactions" :key="transaction.CardNumber">
                        <td>{{ transaction.CardNumber }}</td>
                        <td>{{ transaction.CardAmount }}</td>
                        <td>{{ transaction.CardType }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ModalComponent>
</template>

<script>

import ModalComponent from '@/components/ModalComponent.vue';
// import { toastMixin } from '@/mixins/toastMixin.js';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
import { InventoryMixin } from '@/mixins/InventoryMixin.js';
import { toast } from 'vue3-toastify';

export default {
    mixins: [ PetrolPumpMixin, InventoryMixin ],
    components: {
        ModalComponent,
    },
    data() {
        return {
            showModal: {
                add: false,
                mobil: false,
                employeeExpense: false,
                cardTransactions: false,
                addExpense: false,
            },
            modalType: '',
            pumpId: null,
            pumpRent: 0,
            formDate: null,
            petrolpumps: [],
            dieselPoints: [],
            petrolPoints: [],
            petrolReading: 0,
            dieselReading: 0,
            petrolPriceToday: 0,
            dieselPriceToday: 0,
            InCash: 0,
            DieselSold: 0,
            PetrolSold: 0,
            MobilOilSold: 0,
            StaffExpense: 0,
            DailyExpense: 0,
            ExpenseDetail: null,
            TotalExpenses: 0,

            closingPetrolQty: 0,
            closingDieselQty: 0,

            customers: [],
            customer: 0,
            cashCredit: 0,
            totalAmount: 0,
            cashReceived: 0,
            transactionMsg:null,
            transactions: [],

            productName: null,
            productPrice: 0,
            productQty: 0,
            sales: [],

            mobilOilProducts: [],

            employees: [],
            employeeID: null,
            employeAmount: null,
            emplOyeeWages: [],

            cardTransactions: [],
            CardNumber: 0,
            CardAmount: 0,
            CardType: null,

            BankDepositAmount: 0,
            BankDepositAccount: 0,
            TTDiesel: 0,
            TTPetrol: 0,

            CashInHand: 0,

            expenses: [],
            expenseAmount: null,
            expenseEmployee: null,


        };
    },
    mounted() {
        this.fetchPriceData();
        this.fetchPumpData();
        this.getCustomers();
        this.getMobilOilProducts();
        this.getEmployees();
        this.getCashInHand();
    },

    created() {
        this.pumpId = this.$route.params.id;

        this.getInventory(this.pumpId)
            .then(inventory => {
                this.closingPetrolQty = inventory.ClosingPetrolQty;
                this.closingDieselQty = inventory.ClosingDieselQty;
            })
            .catch(error => {
                console.error(error);
            });



        this.axios.get(`${this.API_URL}get_pump_details.php`, {
            params: {
                PumpID: this.pumpId
            }
        }).then((response) => {
            const dataToMap = Array.isArray(response.data) ? response.data : [];
            this.petrolpumps = dataToMap.map(point => ({
                ...point,
                Reading: 0,
                TodayCount: 0,
            }));
            this.dieselPoints = this.petrolpumps.filter(item => item.PointType === 'Diesel');
            this.petrolPoints = this.petrolpumps.filter(item => item.PointType === 'Petrol');
        }).catch((error) => {
            console.error('Error fetching Petrol Pumps:', error);
        });


    },
    methods: {
        openModal(type) {
            this.modalType = type;
            this.showModal[type] = true;
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        addTransaction() {
            this.cashReceived = this.cashReceived === '' ? 0 : this.cashReceived;
            this.totalAmount = this.totalAmount === '' ? 0 : this.totalAmount;

            const newTransaction = {
                customer: this.customer,
                totalAmount: this.totalAmount,
                cashReceived: this.cashReceived,
                transactionMsg: this.transactionMsg,
                creditAmount: parseFloat(this.totalAmount) - parseFloat(this.cashReceived),
            };
            this.transactions.push(newTransaction);
        },

        addSale() {

            const foundObject = this.mobilOilProducts.find(obj => obj.ProductId === this.productName);
            let price = foundObject.price

            const newSale = {
                productId: this.productName,
                qty: this.productQty,
                price: price,
                totalPrice: parseFloat(this.productQty) * parseFloat(price),
            };
            this.sales.push(newSale);
            this.sumMobilOilSales;
        },
        saveMobilOilSales() {
            const data = {
                tableName: 'mobiloilsales',
                data: {
                    PumpID: this.pumpId,
                    Date: this.formDate,
                    Price: this.sumMobilOilSales,
                },
            };
            this.axios.post(`${this.API_URL}insert.php`, data)
        },
        saveCreditTransactions() {

            this.transactions.forEach(async t => {
                let closingBalance = await this.getClosingBalance(t.customer);

                let cb = (parseFloat(closingBalance.ClosingBalance) + parseFloat(t.totalAmount)) - parseFloat(t.cashReceived);
                let data = {
                    CustomerId: t.customer,
                    ReceivedAmount: t.cashReceived,
                    transactionMsg: t.transactionMsg,
                    TotalAmount: t.totalAmount,
                    ClosingBalance: cb,
                    Date: this.formDate,
                }
                const apiUrl = `${this.API_URL}insert.php`;
                const requestData = {
                    tableName: 'transactionhistory',
                    data: data
                };
                this.axios.post(apiUrl, requestData);
            });
        },
        async getClosingBalance(CustomerId) {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM transactionhistory WHERE CustomerId = ${CustomerId} ORDER BY Date DESC LIMIT 1; `,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response?.data[0]?.ClosingBalance) {
                return {
                    ClosingBalance: response.data[0].ClosingBalance,
                }
            } else {
                return {
                    ClosingBalance: 0,
                }
            }
        },
        async getMobilOilProducts() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM mobiloils WHERE PumpID = ${this.pumpId};`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            this.mobilOilProducts = response.data
        },
        updateDieselPointReading(FillingPointID, Reading) {
            const pointToUpdate = this.dieselPoints.find(point => point.FillingPointID === FillingPointID);
            if (pointToUpdate) {
                const todayCount = Reading - pointToUpdate.lastReading;
                pointToUpdate.TodayCount = Math.max(todayCount, 0);
            }
            this.sumReadings();
        },
        updatePetrolPointReading(FillingPointID, Reading) {
            const pointToUpdate = this.petrolPoints.find(point => point.FillingPointID === FillingPointID);
            if (pointToUpdate) {
                const todayCount = Reading - pointToUpdate.lastReading;
                pointToUpdate.TodayCount = Math.max(todayCount, 0);
            }
            this.sumReadings();
        },
        async saveBankDeposit() {

            let TotalAmountInBank = await this.getTotalAmountInBank();
            console.log(TotalAmountInBank);
            let data = {
                PumpID: this.pumpId,
                date: this.formDate,
                ReceivedInAccount: this.BankDepositAmount,
                TotalAmountInBank: parseFloat(TotalAmountInBank.TotalAmountInBank) + parseFloat(this.BankDepositAmount),
                accountNumber: this.BankDepositAccount,
            }
            const apiUrl = `${this.API_URL}insert.php`;
            const requestData = {
                tableName: 'bankdeposits',
                data: data
            };
            console.log(data);
            this.axios.post(apiUrl, requestData);
        },
        async getTotalAmountInBank() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM bankdeposits WHERE PumpID = ${this.pumpId} ORDER BY id DESC LIMIT 1; `,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response?.data[0]?.TotalAmountInBank) {
                return {
                    TotalAmountInBank: response.data[0].TotalAmountInBank,
                }
            } else {
                return {
                    TotalAmountInBank: 0,
                }
            }
        },


        submitData() {
            if (this.formDate == null || this.formDate == undefined) {
                console.log(this.formDate);
                toast.error('Please add Date', {
                    autoClose: 2000,
                });
                return;
            }
            const apiUrl = `${this.API_URL}insert.php`;
            const requestData = {
                tableName: 'dailyreports',
                data: {
                    PumpID: this.pumpId,
                    ReportDate: this.formDate,
                    PumpRent: this.pumpRent,
                    DieselSold: this.DieselSold,
                    PetrolSold: this.PetrolSold,
                    MobilOilSold: this.sumMobilOilSales,
                    StaffExpense: this.sumemplOyeeWages,
                    DailyExpense: this.DailyExpense,
                    ExpenseDetail: this.ExpenseDetail,
                    PetrolPrice: parseFloat(this.petrolPriceToday),
                    DieselPrice: parseFloat(this.dieselPriceToday),
                    InCash: this.allTotalCash,
                    CreditAmount: this.sumTransactions,
                    CardTransactions: this.sumCardTransactions,
                    BankDepositAmount: this.BankDepositAmount,
                    BankDepositAccount: this.BankDepositAccount,
                    TTDiesel: this.TTDiesel,
                    TTPetrol: this.TTPetrol,
                }
            };
            this.axios.post(apiUrl, requestData).then(() => {
                this.updateLastReading();
                this.updateInventory();
                if (this.sales.length > 0) {
                    this.mobilOilStockUpdate();
                }
                this.saveMobilOilSales();
                if (this.transactions.length > 0) {
                    this.saveCreditTransactions();
                }
                if (this.emplOyeeWages.length > 0) {
                    this.saveWages();
                }
                if (this.cardTransactions.length > 0) {
                    this.saveCardTransactions();
                }
                this.saveBankDeposit();
            }).catch((error) => {
                console.error(error);
            });
        },
        sumReadings() {

            this.PetrolSold = this.petrolPoints.reduce((sum, obj) => {
                if (obj.TodayCount !== undefined) {
                    return Math.round((sum + Math.max(obj.TodayCount, 0)) * 100) / 100;
                }
                return sum;
            }, 0);

            this.DieselSold = this.dieselPoints.reduce((sum, obj) => {
                if (obj.TodayCount !== undefined) {
                    return Math.round((sum + Math.max(obj.TodayCount, 0)) * 100) / 100;
                }
                return sum;
            }, 0);
        },
        updateTotalExpenses() {
            this.TotalExpenses = this.StaffExpense + this.DailyExpense;
        },
        async updateInventory() {
            const lastQty = await this.lastEntryInventoryData(this.pumpId);

            let cpq = parseFloat(lastQty.ClosingPetrolQty) - parseFloat(this.PetrolSold) + parseFloat(this.TTPetrol);
            let cdq = parseFloat(lastQty.ClosingDieselQty) - parseFloat(this.DieselSold) + parseFloat(this.TTDiesel)
            let data = {
                PumpID: this.pumpId,
                Date: this.formDate,
                ReceivedPetrolQty: 0,
                ReceivedDieselQty: 0,
                ClosingPetrolQty: cpq < 0 ? 0 : cpq,
                ClosingDieselQty: cdq < 0 ? 0 : cdq,
            }
            // console.log(data);
            this.submitInventoryData(data);
        },
        updateLastReading() {
            let petrolPoints = this.petrolPoints
            let dieselPoints = this.dieselPoints
            petrolPoints.forEach(point => {
                let data = {
                    FillingPointID: parseFloat(point.FillingPointID),
                    ReadingDate: this.formDate,
                    PumpID: this.pumpId,
                    Quantity: point.Reading,
                    HiddenReading: point.HiddenReading
                }
                const apiUrl = `${this.API_URL}insert.php`;
                const requestData = {
                    tableName: 'readings',
                    data: data
                };
                this.axios.post(apiUrl, requestData);
            });

            dieselPoints.forEach(point => {
                let data = {
                    FillingPointID: parseFloat(point.FillingPointID),
                    ReadingDate: this.formDate,
                    PumpID: this.pumpId,
                    Quantity: point.Reading,
                    HiddenReading: point.HiddenReading
                }
                const apiUrl = `${this.API_URL}insert.php`;
                const requestData = {
                    tableName: 'readings',
                    data: data
                };
                this.axios.post(apiUrl, requestData);
            });
        },
        async getCustomers() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM customers WHERE PumpID = ${this.pumpId};`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            this.customers = response.data
        },
        getCustomerName(customerId) {
            const customer = this.customers.find(cust => cust.CustomerId == customerId);
            return customer ? customer.CustomerName : 'Customer not found';
        },
        mobilOilStockUpdate() {
            this.sales.forEach(async s => {
                let inStock = await this.InStock(this.pumpId, s.productId);
                const data = {
                    tableName: 'mobiloilstock',
                    data: {
                        ProductId: s.productId,
                        ReceivedQty: 0,
                        Date: this.formDate,
                        SoldQty: s.qty,
                        InStock: parseFloat(inStock.InStock) - parseFloat(s.qty),
                        PumpID: this.pumpId,
                    },
                };
                this.axios.post(`${this.API_URL}insert.php`, data)
            });
        },
        async InStock(pumpId, productId) {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM mobiloilstock WHERE PumpID = ${pumpId} AND ProductId = ${productId} ORDER BY Date DESC LIMIT 1;`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response?.data[0]?.InStock) {
                return {
                    InStock: response.data[0].InStock,
                }
            } else {
                return {
                    InStock: 0,
                }
            }
        },

        async getEmployees() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM employees WHERE PumpID = ${this.pumpId};`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            this.employees = response.data
        },
        addEmployeeWage() {
            const employeeWage = {
                EmployeeID: this.employeeID,
                Amount: this.employeAmount,
                PumpID: this.pumpId,
                Date: this.formDate,
            };
            this.emplOyeeWages.push(employeeWage);
            this.StaffExpense = this.sumemplOyeeWages;
        },
        saveWages() {
            const data = {
                tableName: 'wages',
                data: this.emplOyeeWages,
            };
            this.axios.post(`${this.API_URL}save_wages.php`, data)
        },

        addCardTransaction() {
            const newTransaction = {
                PumpID: this.pumpId,
                CardNumber: this.CardNumber,
                CardAmount: this.CardAmount,
                CardType: this.CardType,
                transferredAmount: 0,
                transferredAccount: null,
                Date: this.formDate,
            };
            this.cardTransactions.push(newTransaction);
        },
        saveCardTransactions() {
            const data = {
                tableName: 'cardtransactions',
                data: this.cardTransactions,
            };

            this.axios.post(`${this.API_URL}save_card_transactions.php`, data)
        },

        async getCashInHand() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT InCash FROM dailyreports WHERE PumpId = ${this.pumpId} ORDER BY ReportDate DESC LIMIT 1;`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            // eslint-disable-next-line no-prototype-builtins
            if (response.data && response.data.length > 0 && response.data[0].hasOwnProperty('InCash')) {
                this.CashInHand = response.data[0].InCash;
            } else {
                this.CashInHand = 0;
            }
        },
        ThisDate() {
            console.log(this.formDate);
        }
    },
    computed: {
        calTotalExpenses() {
            return this.StaffExpense + this.DailyExpense;
        },
        sumTransactions() {
            let tt = 0;
            this.transactions.forEach(t => {
                tt = tt + t.creditAmount;
            });
            return tt;
        },
        sumReceived(){
            let tt = 0;
            this.transactions.forEach(t => {
                tt = tt + t.cashReceived;
            });
            return tt;
        },
        sumMobilOilSales() {
            let tt = 0;
            this.sales.forEach(t => {
                tt = tt + t.totalPrice;
            });
            return tt;
        },
        sumemplOyeeWages() {
            let ew = 0;
            this.emplOyeeWages.forEach(e => {
                ew = ew + e.Amount;
            });
            return ew;
        },
        sumCardTransactions() {
            let ew = 0;
            this.cardTransactions.forEach(e => {
                ew = ew + e.CardAmount;
            });
            return ew;
        },
        totalSales() {
            return (this.sumMobilOilSales) + (this.PetrolSold * this.petrolPriceToday) + (this.DieselSold * this.dieselPriceToday);
        },
        allTotalCash() {
            return this.totalSales - this.calTotalExpenses - this.sumTransactions - this.sumCardTransactions - this.BankDepositAmount - this.TTAmount + parseFloat(this.CashInHand) - this.pumpRent;
        },
        TTAmount() {
            let d = parseFloat(this.TTDiesel * this.dieselPriceToday);
            let p = parseFloat(this.TTPetrol * this.petrolPriceToday);
            return parseFloat(d + p);
        }


    },
    watch: {
        StaffExpense: "updateTotalExpenses",
        DailyExpense: "updateTotalExpenses"
    },
};
</script>