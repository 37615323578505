<template>
    <div class="container">


        <div>
            <div class="row align-items-center">
                <div class="col-lg-8 p-2">
                    <h2> Mobiloil Products</h2>
                </div>
                <div class="col-lg-4 ">
                    <div class="row justify-content-around">

                        <span class="col-lg-5 btn btn-primary">
                            <router-link to="/add-product" class="dropdown-item">Add MobilOil Product</router-link>
                        </span>
                        <span class="col-lg-5 btn btn-primary">
                            <router-link to="/product-stock" class="dropdown-item">Manage Stock</router-link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <table class="table" v-if="products.length > 0">
            <thead>
                <tr>
                    <th scope="col">Company</th>
                    <th scope="col">Name</th>
                    <th scope="col">Variant</th>
                    <th scope="col">Price</th>
                    <th scope="col">InStock</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="product in products" :key="product.ProductId">
                    <th scope="row">{{ product.company }}</th>
                    <td>{{ product.name }}</td>
                    <td>{{ product.variant }}</td>
                    <td>{{ product.price }}</td>
                    <td>{{ product.LastInStock }}</td>
                    <td>
                        <button @click="openModal('edit'), getProduct(product.ProductId)" class="btn btn-success btn-container" >Edit</button>
                        <button @click="deleteMobilOil(product.ProductId)" class="btn btn-danger" v-if="this.userStore?.user?.UserType == 'super_admin'">Delete</button>
                    </td>

                </tr>

            </tbody>
        </table>
    </div>


    <Modal :showModal="showModal.edit" @close="closeModal('edit')">
        <h3>Edit Product</h3>
        <form class="row" @submit.prevent="onSubmit">
            <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Name</span>
                    <input type="text" class="form-control" v-model="name">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Company</span>
                    <input type="text" class="form-control" v-model="company">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Price</span>
                    <input type="number" class="form-control" v-model="price" step="any">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="inputGroupSelect01">Variant</label>
                    </div>
                    <select class="form-control" id="product" v-model="variant">
                        <option value="0.25L">0.25 L</option>
                        <option value="0.5L">0.5 L</option>
                        <option value="1L">1 L</option>
                        <option value="3L">3 L</option>
                        <option value="4L">4 L</option>
                        <option value="10L">10 L</option>
                        <option value="20L">20 L</option>
                    </select>
                </div>
            </div>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">Pump</label>
                </div>
                <select class="form-control" id="pump" v-model="pump">
                    <option v-for="pump in allPumps" :key="pump.PumpID" :value="pump.PumpID">
                        {{ pump.PumpName }}
                    </option>
                </select>
            </div>
            <button type="submit" class="btn btn-primary mt-2" @click="saveEditedData()">Save Data</button>
        </form>
    </Modal>
</template>

<script>
import Modal from '@/components/ModalComponent.vue';
import { toastMixin } from '@/mixins/toastMixin.js';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';

export default {
    mixins: [toastMixin, GlobalMixin, PetrolPumpMixin],
    components: {
        Modal,
    },

    data() {
        return {
            name: null,
            company: null,
            variant: null,
            ProductId: null,
            price: 0,
            // allPumps: pumpStore.pumps,
            pump: null,
            products: [],
            showModal: {
                edit: false,
                mobil: false
            },
        };
    },
    mounted() {
        document.title = 'Mobil Oil';

        // console.log('Store Name:', this.userStore.user);
        setTimeout(() => {
            console.log('Store Name:', this.userStore.user);
        }, 1000)
    },

    created() {
        this.getDetails();
    },
    methods: {
        openModal(type) {
            this.modalType = type;
            this.showModal[type] = true;
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        async getDetails() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: {
                    query: `SELECT 
                            m.ProductId,
                            m.name,
                            m.price,
                            m.company,
                            m.variant,
                            m.PumpID,
                            s.InStock AS LastInStock
                            FROM 
                            mobiloils m
                            LEFT JOIN (
                            SELECT 
                                ProductId,
                                MAX(Date) AS LatestDate
                            FROM 
                                mobiloilstock
                            GROUP BY 
                                ProductId
                            ) latest ON m.ProductId = latest.ProductId
                            LEFT JOIN mobiloilstock s ON latest.ProductId = s.ProductId AND latest.LatestDate = s.Date
                            WHERE m.PumpID = ${this.globalPumpId};`,
                }
            });
            this.products = response.data;
        },
        async getProduct(id) {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM mobiloils WHERE ProductId = ${id}`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            console.log(response.data);
            this.ProductId = id;
            this.name = response.data[0].name;
            this.company = response.data[0].company;
            this.price = response.data[0].price;
            this.variant = response.data[0].variant;
            this.pump = response.data[0].PumpID;
        },
        saveEditedData() {
            this.axios.post(`${this.API_URL}edit_query.php`, {
                query: `UPDATE mobiloils SET name = '${this.name}', company = '${this.company}',
                    price = '${this.price}', variant = '${this.variant}', PumpID = '${this.pump}' WHERE ProductId = ${this.ProductId};`
            }).then(() => {
                this.successToast();
                this.closeModal('edit');
            }).catch(error => {
                console.log(error);
            });
        },
        async deleteMobilOil(id){
            try {
                const query =  [
                    {"table": "mobiloils", "condition": `ProductId = ${id}`}
                ];
                
                await this.hardDeleteRequest(query);
                this.deleteToast();
                this.getDetails();
            } catch (error) {
                // Handle error
                console.error('Error deleting employee:', error);
            }
        },
    }
}
</script>