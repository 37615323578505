<template>
    <div class="container  ">
        <div class="row align-items-center my-3">
            <div class="col-lg-6">
                <h2>Customers</h2>
            </div>
            <div class="col-lg-6 text-right">
                <button class="btn btn-primary" @click="openModal('add')">Add Customer</button>
            </div>
        </div>

        <ModalComponent :showModal="showModal.add" @close="closeModal('add')">
            <div v-if="modalType === 'add'" class="modal-content">
                <form class="row" @submit.prevent="onSubmit">
                    <div class="form-group col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Customer Name</span>
                            <input type="text" class="form-control" id="petrol" v-model="customerName">
                        </div>
                    </div>
                    <div class="form-group col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Phone Number</span>
                            <input type="text" class="form-control" id="diesel" v-model="phNumber">
                        </div>
                    </div>
                    <div class="form-group col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Address</span>
                            <input type="text" class="form-control" v-model="address">
                        </div>
                    </div>
                    
                    <button type="submit" class="btn btn-primary mt-2">Submit</button>
                </form>
            </div>
        </ModalComponent>
    </div>

    <div class="container">
        <table class="table" v-if="customers.length > 0">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Goto Details</th>
                    <th scope="col" v-if="this.userStore?.user?.UserType == 'super_admin'">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="customer in customers" :key="customer.CustomerId">
                    <th scope="row">{{ customer.CustomerId }}</th>
                    <td>{{ customer.CustomerName }}</td>
                    <td>{{ customer.PhoneNumber }}</td>
                    <td>{{ customer.Address }}</td>
                    <!-- <td>{{ customer.ClosingBalance }}</td> -->
                    <td>
                        <div class="btn btn-primary">
                            <router-link class="text-white text-decoration-none"
                                :to="{ name: 'CustomerDetails', params: { id: customer.CustomerId } }">History
                            </router-link>
                        </div>
                    </td>
                    <td class="btn-group" role="group" v-if="this.userStore?.user?.UserType == 'super_admin'">
                        <button @click="openModal('edit', customer)" class="btn btn-primary" >Edit</button>
                        <button @click="deleteCustomer(customer.CustomerId)" class="btn btn-danger" >Delete</button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <ModalComponent :showModal="showModal.edit" @close="closeModal('edit')">
        <div v-if="modalType === 'edit'" class="modal-content">
            <h4>Edit Customer</h4>
            <form class="row" @submit.prevent="onEdit">
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Customer Name</span>
                        <input type="text" class="form-control" id="petrol" v-model="editCustomer.CustomerName">
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Phone Number</span>
                        <input type="text" class="form-control" id="diesel" v-model="editCustomer.PhoneNumber">
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Address</span>
                        <input type="text" class="form-control" v-model="editCustomer.Address">
                    </div>
                </div>
                
                <button type="submit" class="btn btn-primary mt-2">Submit</button>
            </form>
        </div>
    </ModalComponent>
</template>

<script>
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
import { toastMixin } from '@/mixins/toastMixin.js';
import ModalComponent from '@/components/ModalComponent.vue';
export default {
    mixins: [GlobalMixin, toastMixin],
    components: {
        ModalComponent,
    },
    data() {
        return {
            showModal: {
                add:false,
                edit: false
            },
            customerName: null,
            phNumber: null,
            address: null,
            customers: [],
            pump: null,
            editCustomer:{}
        };
    },

    mounted() {
        document.title = 'Customers';
        console.log(this.userStore.user);
    },
    async created() {
        this.getCustomers();
    },

    methods: {
        openModal(type, e = null) {
            this.modalType = type;
            this.showModal[type] = true;
            if (type === 'edit' && e) {
                console.log(e);
                this.editCustomer = { ...e };
            }
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        async getCustomers(){
            let query = `SELECT 
            c.*,
            th.ClosingBalance AS ClosingBalance
            FROM 
            customers c
            LEFT JOIN (
            SELECT 
                CustomerId,
                ClosingBalance
            FROM 
                transactionhistory th1
            WHERE 
                th1.TransactionId IN (
                    SELECT 
                        MAX(TransactionId) AS last_entry
                    FROM 
                        transactionhistory th2
                    WHERE 
                        th1.CustomerId = th2.CustomerId
                    GROUP BY 
                        th2.CustomerId
                )
            ) th ON c.CustomerId = th.CustomerId WHERE PumpID  = ${this.globalPumpId};`;
            this.customers = await this.getRequest(query);
        },
        async updateResult() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM customers WHERE PumpID = ${this.globalPumpId}`,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (Array.isArray(response.data)) {
                this.customers = [...response.data];
            } else {
                console.error('Invalid data format in response:', response.data);
                this.customers = []
            }
        },
        onSubmit() {

            const data = {
                tableName: 'customers',
                data: {
                    CustomerName: this.customerName,
                    PhoneNumber: this.phNumber,
                    Address: this.address,
                    PumpID: this.globalPumpId
                },
            };

            this.axios.post(`${this.API_URL}insert.php`, data)
                .then(() => {
                    this.successToast();
                    this.getCustomers();
                    this.closeModal('add')
                })
                .catch(error => {
                    console.error(error);
                });
        },
        async deleteCustomer(id) {
            try {
                let query = `DELETE FROM customers WHERE CustomerId = '${id}';`;
                await this.getRequest(query);
                this.getCustomers();
                this.deleteToast();
            } catch (error) {
                // Handle error
                console.error('Error deleting employee:', error);
            }
        },
        async onEdit() {
            
            let data = {
                CustomerName: this.editCustomer.CustomerName,
                PhoneNumber: this.editCustomer.PhoneNumber,
                Address: this.editCustomer.Address,
                PumpID: this.globalPumpId
            };
            try {
                await this.updateRequest('customers', 'CustomerId', this.editCustomer.CustomerId, data);
                this.getCustomers();
                this.successToast();
                this.closeModal('edit');
            } catch (error) {
                console.error('Error updating employee:', error);
            }
        },
        
    }
}
</script>