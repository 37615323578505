import { toastMixin } from '@/mixins/toastMixin.js';
export const InventoryMixin = {
    mixins: [ toastMixin ],
    data() {
        return {
            
            
        }
    },
    methods: {
        async submitInventoryData(data) {
            const apiUrl = `${this.API_URL}insert.php`;
            const requestData = {
                tableName: 'inventory',
                data: data
            };
            this.axios.post(apiUrl, requestData).then(() => {
                this.successToast();
            }).catch((error) => {
                console.error(error);
            });
        },
        async lastEntryInventoryData(pumpId) {
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT * FROM inventory WHERE PumpID = ${pumpId} ORDER BY ID DESC LIMIT 1;`,
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                });

                return {
                    ClosingPetrolQty : response.data[0].ClosingPetrolQty,
                    ClosingDieselQty : response.data[0].ClosingDieselQty,
                }
            } catch (error) {
                // console.error('Error fetching Pump Data', error);
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                return {
                    ClosingPetrolQty : 0,
                    ClosingDieselQty : 0,
                }
            }
        },
        async getInventory(pumpId) {

            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT * FROM inventory WHERE PumpID = ${pumpId} ORDER BY ID DESC LIMIT 1`,
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                });

                // console.log(response.data[0].ClosingPetrolQty, response.data[0].ClosingDieselQty);
                return {
                    ClosingPetrolQty : response.data[0].ClosingPetrolQty,
                    ClosingDieselQty : response.data[0].ClosingDieselQty,
                }
            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                return {
                    ClosingPetrolQty : 0,
                    ClosingDieselQty : 0,
                }
            }
        }

    }
}