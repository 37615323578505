/* eslint-disable no-prototype-builtins */
export const PetrolPumpMixin = {
    data() {
        return {
            pumpDetail: [],
            allPumps: [],
        }
    },
    created() {
        this.getAllPumps();
    },
    methods: {
        async fetchPriceData() {
            try {
                const response = await this.axios.get(`${this.API_URL}get_pricings.php`, {
                    params: {
                        PumpID: this.pumpId
                    }
                });
                if (
                    response.data &&
                    response.data.length > 0 &&
                    response.data[0].hasOwnProperty('DieselPrice')
                ) {
                    this.dieselPriceToday = response.data[0].DieselPrice;
                } else {
                    this.dieselPriceToday = 0; // Set a default value if the condition is not met
                }

                // For petrolPriceToday
                if (
                    response.data &&
                    response.data.length > 0 &&
                    response.data[0].hasOwnProperty('PetrolPrice')
                ) {
                    this.petrolPriceToday = response.data[0].PetrolPrice;
                } else {
                    this.petrolPriceToday = 0; // Set a default value if the condition is not met
                }
                // console.log(this.dieselPriceToday, this.petrolPriceToday);
            } catch (error) {
                console.error('Error fetching prices:', error);
            }
        },
        async fetchPumpData() {
            this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM petrolpumps WHERE PumpID = ${this.pumpId} `,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then((response) => {
                this.pumpDetail = response.data[0];
            }).catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            });
        },
        async getAllPumps() {
            this.axios.get(`${this.API_URL}get_table_data.php`, {
                params: {
                    tableName: 'petrolpumps'
                }
            }).then((response) => {
                this.allPumps = response.data;
            }).catch((error) => {
                console.error('Error fetching Petrol Pumps:', error);
            });
        }
    }
}