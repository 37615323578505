<template>
    <div class="container mt-5 ">

        <button @click="openModal('add')" class="btn btn-primary">Add Employee</button>
        <table class="table" v-if="employees.length > 0">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Salary</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="employee in employees" :key="employee.EmployeeID">
                    <th scope="row">{{ employee.EmployeeID }}</th>
                    <td>{{ employee.Name }}</td>
                    <td>{{ employee.PhoneNumber }}</td>
                    <td>{{ employee.Address }}</td>
                    <td>{{ employee.Salary }}</td>
                    <td>
                        
                        <div class="btn-group ml-2" role="group">
                            <button class="btn btn-success">
                                <router-link class="text-white text-decoration-none"
                                    :to="{ name: 'EmployeesDetails', params: { id: employee.EmployeeID } }">History
                                </router-link>
                            </button>
                            <button @click="openModal('edit', employee)" class="btn btn-primary" v-if="this.userStore?.user?.UserType == 'super_admin'">Edit</button>
                            <button @click="deleteEmployee(employee.EmployeeID)" class="btn btn-danger" v-if="this.userStore?.user?.UserType == 'super_admin'">Delete</button>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>

    <ModalComponent :showModal="showModal.add" @close="closeModal('add')">
        <div v-if="modalType === 'add'" class="modal-content">
            <h4>Add Employee</h4>
            <form class="row" @submit.prevent="onSubmit">
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Name</span>
                        <input type="text" class="form-control" id="petrol" v-model="Name">
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Phone Number</span>
                        <input type="text" class="form-control" id="diesel" v-model="PhoneNumber">
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Address</span>
                        <input type="text" class="form-control" v-model="Address">
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Salary</span>
                        <input type="text" class="form-control" v-model="Salary">
                    </div>
                </div>
                <button type="submit" class="btn btn-primary mt-2">Submit</button>
            </form>

        </div>
    </ModalComponent>
    <ModalComponent :showModal="showModal.edit" @close="closeModal('edit')">
        <div v-if="modalType === 'edit'" class="modal-content">
            <h4>Edit Employee</h4>
            <form class="row" @submit.prevent="onEdit">
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Name</span>
                        <input type="text" class="form-control" v-model="editEmployee.Name">
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Phone Number</span>
                        <input type="text" class="form-control" v-model="editEmployee.PhoneNumber">
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Address</span>
                        <input type="text" class="form-control" v-model="editEmployee.Address">
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Salary</span>
                        <input type="text" class="form-control" v-model="editEmployee.Salary">
                    </div>
                </div>
                <button type="submit" class="btn btn-primary mt-2">Submit</button>
            </form>
        </div>
    </ModalComponent>
</template>

<script>
import ModalComponent from '@/components/ModalComponent.vue';
import { toastMixin } from '@/mixins/toastMixin.js';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
export default {
    mixins: [GlobalMixin, toastMixin],
    components: {
        ModalComponent,
    },
    data() {
        return {
            showModal: {
                add: false,
                edit: false
            },
            modalType: '',
            Name: null,
            Salary: null,
            PhoneNumber: null,
            Address: null,
            employees: [],
            editEmployee: {},
        };
    },
    
    mounted() {
        document.title = 'Employees';
        console.log(this.userStore.user);
    },
    created() {
        this.getEmployees();
    },

    methods: {
        async onEdit() {
            
            let data =  {
                Name: this.editEmployee.Name,
                PhoneNumber: this.editEmployee.PhoneNumber,
                Address: this.editEmployee.Address,
                Salary: this.editEmployee.Salary
            }
            try {
                await this.updateRequest('employees','EmployeeID', this.editEmployee.EmployeeID, data);
                this.getEmployees();
                this.successToast();
                this.closeModal('edit');
            } catch (error) {
                console.error('Error updating employee:', error);
            }
        },
        openModal(type, employee = null) {
            this.modalType = type;
            this.showModal[type] = true;
            if (type === 'edit' && employee) {
                // Set the editEmployee object to the employee being edited
                this.editEmployee = { ...employee };
            }
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        onSubmit() {

            const data = {
                tableName: 'employees',
                data: {
                    PumpID: this.globalPumpId,
                    Name: this.Name,
                    PhoneNumber: this.PhoneNumber,
                    Address: this.Address,
                    Salary: this.Salary
                },
            };

            this.axios.post(`${this.API_URL}insert.php`, data)
                .then(() => {
                    this.successToast();
                    this.getEmployees();
                    this.closeModal('add');
                })
                .catch(error => {
                    console.error(error);
                });
        },
        async getEmployees() {
            let query = `SELECT
                    E.EmployeeID,
                    E.Name,
                    E.PhoneNumber,
                    E.Address,
                    E.Salary,
                    SUM(W.Amount) AS Amount
                FROM
                    employees E
                LEFT JOIN
                    wages W ON E.EmployeeID = W.EmployeeID
                WHERE
                    E.PumpID = ${this.globalPumpId}
                GROUP BY
                    E.EmployeeID, E.Name, E.PhoneNumber, E.Address;`;

            this.employees = await this.getRequest(query);
            console.log(this.employees);


        },
        async deleteEmployee(employeeID) {
            try {
                const query =  [
                    {"table": "wages", "condition": `EmployeeID = ${employeeID}`},
                    {"table": "employees", "condition": `EmployeeID = ${employeeID}`}
                ];
                
                await this.hardDeleteRequest(query);
                this.getEmployees();
                this.deleteToast();
            } catch (error) {
                // Handle error
                console.error('Error deleting employee:', error);
            }
        }
    }
}
</script>