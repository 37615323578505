<template>
    <div class="container">
        <div class="row align-items-center hprint">
            <div class="col-lg-10 p-2">
                <h2>Bank Deposit</h2>
            </div>
            <div class="col-lg-2 text-right">
                <button @click="openModal('addRecord')" class="btn btn-primary">Add Record</button>
            </div>
        </div>

        <div class="row hprint">
            <div class="col-lg-6">
                <button @click="exportToPDF()" class="btn btn-danger ml-2">PDF</button>
            </div>
            <div class="row col-lg-4">
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="minDate">
                </div>
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="maxDate">
                </div>
            </div>
            <div class="col-lg-2 text-end">
                <button @click="dateSelected()" class="btn btn-secondary">Show Data</button>
            </div>
        </div>
        <div >
            <table class="table" id="myTable">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Deposit Account</th>
                        <th scope="col">Transferred Amount</th>
                        <th scope="col">Account No.</th>
                        <th scope="col">Product</th>
                        <th scope="col">Qty</th>
                        <th scope="col">TL</th>
                        <th scope="col">In Bank</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(r, index) in records" :key="r.ReportID">
                        <td>{{ index }}</td>
                        <td>{{ formatDate(r.date) }}</td>
                        <td>{{ r.Customer }}</td>
                        <th>{{ r.ReceivedInAccount }}</th>
                        <th>{{ r.PaidFromAccount }}</th>
                        <td>{{ r.accountNumber }}</td>
                        <td>{{ r.product }}</td>
                        <td>{{ r.qty }}</td>
                        <td>{{ r.tl }}</td>
                        <td>{{ r.TotalAmountInBank }}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>

    <ModalComponent :showModal="showModal.addRecord" @close="closeModal('addRecord')">
        <h4>Add Record</h4>
        <form action="" @submit.prevent>
            <div class="input-group mb-1">
                <span class="input-group-text">Transfer Amount</span>
                <input type="number" class="form-control" v-model="PaidFromAccount">
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Transfer Account Number</span>
                <input type="number" class="form-control" v-model="accountNumber">
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Customer</span>
                <input type="text" class="form-control" v-model="customer">
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Product</span>
                <input type="text" class="form-control" v-model="product">
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Quantity</span>
                <input type="text" class="form-control" v-model="qty">
            </div>
            <div class="input-group mb-1">
                <span class="input-group-text">Vehicle</span>
                <input type="text" class="form-control" v-model="tl">
            </div>
            <div class="col text-right">
                <div class="input-group">
                    <span class="input-group-text">Date</span>
                    <input type="date" class="form-control" name="" id="" v-model="date">
                </div>
            </div>

            <button @click="saveData()" class="btn btn-primary mt-3">Save</button>
        </form>
    </ModalComponent>
</template>


<script>
import ModalComponent from '@/components/ModalComponent.vue';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
import { toastMixin } from '@/mixins/toastMixin.js';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
export default {
    mixins: [toastMixin, GlobalMixin, PetrolPumpMixin],
    components: {
        ModalComponent,
    },
    data() {
        return {
            showModal: {
                addRecord: false,
            },
            minDate: null,
            maxDate: null,
            records: [],
            pumpId: null,
            date: null,
            tl: null,
            product: null,
            customer: null,
            accountNumber: null,
            qty: null,
            PaidFromAccount: null,
            ReceivedInAccount: null,
            TotalAmountInBank: null,
        };
    },

    mounted() {
        document.title = 'Bank Deposits';
    },
    created() {
        this.pumpId = this.$route.params.id;
        this.axios.get(`${this.API_URL}get_query.php`, {
            params: {
                query: `SELECT * FROM bankdeposits
                WHERE PumpID = ${this.pumpId} ORDER BY date ASC`,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            this.records = response.data;
            console.log(response.data);
        }).catch((error) => {
            if (error.response.status == 401) {
                localStorage.removeItem('UserID');
                localStorage.removeItem('expiryDate');
                localStorage.removeItem('userLoggedIn');
                localStorage.removeItem('token');
                this.$router.push('/login');
            }
            console.error('Error fetching Pump Data', error);
        });

        setTimeout(() => {
            this.getTotalAmountInBank()
        }, 1000);
    },

    methods: {
        openModal(type) {
            this.modalType = type;
            this.showModal[type] = true;
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        formatDate(dateString) {
            const formattedDate = new Date(dateString).toLocaleDateString('en-GB');
            return formattedDate;
        },
        saveData() {
            let data = {
                date: this.date,
                PumpID: this.pumpId,
                tl: this.tl,
                product: this.product,
                Customer: this.customer,
                accountNumber: this.accountNumber,
                qty: this.qty,
                PaidFromAccount: this.PaidFromAccount,
                ReceivedInAccount: 0,
                TotalAmountInBank: parseFloat(this.TotalAmountInBank) - parseFloat(this.PaidFromAccount)
            }
            // console.log(data);
            const apiUrl = `${this.API_URL}insert.php`;
            const requestData = {
                tableName: 'bankdeposits',
                data: data
            };
            this.axios.post(apiUrl, requestData).then(() => {
                this.successToast();
                this.closeModal('addRecord');
                window.location.reload();
            }).catch((error) => {
                console.error(error);
            });
        },
        async getTotalAmountInBank() {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM bankdeposits WHERE PumpID = ${this.pumpId} ORDER BY id DESC LIMIT 1; `,
                },
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response?.data[0]?.TotalAmountInBank) {
                this.TotalAmountInBank = response.data[0].TotalAmountInBank;
            } else {
                this.TotalAmountInBank = 0;
            }

            console.log(this.TotalAmountInBank);
        },
        async dateSelected() {
            const minDate = `${this.minDate}`;
            const maxDate = `${this.maxDate}`;
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT * FROM bankdeposits 
                        WHERE PumpID = ${this.pumpId}  AND date >= '${minDate}' AND date <= '${maxDate}' ;`
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.records = response.data;

            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                console.error('Error fetching Pump Data', error);
            }
        },
    }
}
</script>