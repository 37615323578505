// import { toastMixin } from '@/mixins/toastMixin.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'vue3-toastify';
import { useUserStore } from '@/store/UserStore';
export const GlobalMixin = {
    data() {
        return {
            userStore: useUserStore(),
            globalPumpId: localStorage.getItem('PumpId') || 1,
        }
    },
    methods: {
        async getRequest(query) {
            const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: {
                    query: query
                }
            });
            return response.data;
        },
        async hardDeleteRequest(param) {
            const response = await this.axios.post(`${this.API_URL}hard_delete.php`, {
                deletes: param
            }, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            return response.data;
        },
        exportToPDF() {
            // Initialize jsPDF
            const doc = new jsPDF({
                orientation: 'landscape'
            });

            const table = document.getElementById('myTable');

            doc.autoTable({
                html: table,
                styles: { fillColor: [255, 255, 255], fontSize: 6, textColor: [0, 0, 0] },
            });
            const currentDate = new Date();

            const day = String(currentDate.getDate()).padStart(2, '0');
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = currentDate.getFullYear();

            const formattedDate = `${day}/${month}/${year}`;

            doc.save(this.pumpId + '(' + formattedDate + ')');
        },
        validateToken() {
            const token = localStorage.getItem('token');
            const expiryDate = localStorage.getItem('expiryDate');
            if (!token || !expiryDate || new Date() > new Date(expiryDate)) {
                // console.log(token);
                // console.log(new Date(expiryDate));
                // console.log(new Date());
                toast.error('Unauthorized Request', {
                    autoClose: 2000,
                });
                localStorage.removeItem('userLoggedIn');
                localStorage.removeItem('token');
                localStorage.removeItem('expiryDate');
                setTimeout(() => {
                    this.$router.push('/login');
                }, 2000);
                return false;
            }
            return true;
        },
        async updateRequest(tableName, idColumnName, idColumnValue, data) {
            const response = await this.axios.post(`${this.API_URL}update.php`, {
                tableName: tableName,
                idColumnName: idColumnName,
                id: idColumnValue,
                fields: data
            }, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            return response.data;
        },
        async deleteRequest(tableName, idColumnName, id) {
            const response = await this.axios.delete(`${this.API_URL}delete.php`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: {
                    tableName: tableName,
                    idColumnName: idColumnName,
                    id: id
                }
            });
            return response.data;
        }

    },
    computed: {
        // USERDATA (){
        //     return userStore().name;
        // } 
    }
}