import { defineStore } from 'pinia';
import axios from 'axios';

export const useUserStore  = defineStore('userStore',{
    id: 'userStoreId',
    state:() => ({
        user: null,
        pumps:[],
        storeName:'User Store ',

    }),
    actions: {
        setUserData(data) {
            this.user = data;
        },

        async getUserData(){
            console.log(localStorage.getItem('UserID'));
            const response = await axios.get(`https://rsfbrothers.com/api/get_user_data.php`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params:{
                  user_id : localStorage.getItem('UserID')
                }
            });
            if (response.status == 200) {
                this.user = response.data.user; 
                return response.data.user;
            }
        },

        async getPumps() {
            if (!this.loading) {
                axios.get(`https://rsfbrothers.com/api/get_table_data.php`, {
                    params: {
                        tableName: 'petrolpumps'
                    }
                }).then((response) => {
                    this.pumps = response.data;
                    this.loading = true
                }).catch((error) => {
                    console.error('Error fetching Petrol Pumps:', error);
                    this.loading = false
                });
            }
        },
    }
})

