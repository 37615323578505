<template>
    <section class="container"> 
        <h1>DummY Dashboard</h1>

    </section>
</template>
  
<script>
export default{
    mounted() {
        document.title = 'Dashboard | Petrozal';
    }
}
</script>