<template>
  <the-header v-if="showHeader"></the-header>
  <div class="container-fluid gx-0 gy-0">
    <!-- {{userData.user.Username}} -->
    <router-view />

  </div>
  <div class="floting-menu-icon">
    <a class="adminButton" href="#!" @click="toggleFloatingMenu()"><i class="fa fa-cog"></i></a>
  </div>
  <div class="floting-menu" v-if="floatingMenu">
    <!-- <h5>Select Pump</h5> -->
    <div class="dpdwn">
      <!-- <label for="petrolPump">Select Petrol Pump:</label>
            <select id="petrolPump" v-model="selectedPump" @change="handleSelection">
                <option v-for="pump in allPumps" :key="pump.PumpID" :value="pump.PumpID">{{ pump.PumpName }}</option>
            </select> -->
      <label for="">Select Pump:</label>
      <select class="form-select" aria-label="Default select example" v-model="selectedPumpId"
        @change="updateLocalStorage">
        <option disabled>Select Pump</option>
        <option v-for="pump in allPumps" :key="pump.PumpID" :value="pump.PumpID"
          :selected="pump.PumpID === selectedPumpId">{{ pump.PumpName }}</option>
      </select>
    </div>
  </div>
</template>

<script>
// import { onBeforeMount } from 'vue';
import { useUserStore } from "./store/UserStore";
import TheHeader from './components/layout/TheHeader.vue';

export default {
  name: 'App',

  components: {
    TheHeader
  },
  data() {
    return {
      selectedPump: null,
      allPumps: [],
      floatingMenu: false,
      selectedPumpId: localStorage.getItem('PumpId') || 1,
    };
  },
  async mounted() {

    const userStore = useUserStore();
    
    const isAuthenticated = localStorage.getItem('userLoggedIn') == 'true';
    const token = localStorage.getItem('token');
    const expiryDate = localStorage.getItem('expiryDate');

    if (!isAuthenticated || !token || !expiryDate || new Date() > new Date(expiryDate)) {
      try {
        await this.axios.get(`${this.API_URL}logout.php`, {
          headers: {
            'Authorization': 'Bearer ' + token
          },
          params: {
            user_id: localStorage.getItem('UserID')
          }
        });
        localStorage.removeItem('userLoggedIn');
        localStorage.removeItem('token');
        localStorage.removeItem('expiryDate');
      } catch (error) {
        console.error('Error logging out:', error);
      }
      setTimeout(() => {
        this.$router.push('/login');
      }, 2000);


    } else {
      this.getAllPumps();
      userStore.getUserData();
    }
  },
  methods: {
    async getAllPumps() {
      this.axios.get(`${this.API_URL}get_table_data.php`, {
        params: {
          tableName: 'petrolpumps'
        }
      }).then((response) => {
        this.allPumps = response.data;
      }).catch((error) => {
        console.error('Error fetching Petrol Pumps:', error);
      });
    },
    toggleFloatingMenu() {
      this.floatingMenu = !this.floatingMenu;
    },
    updateLocalStorage() {
      localStorage.setItem('PumpId', this.selectedPumpId); // Update localStorage when the value changes
      window.location.reload();
    },
  },
  computed: {
    showHeader() {
      return this.$route.name !== 'login';
    },
  }
}

</script>
