<template>
    <div class="container mt-5 ">
        <form class="row" @submit.prevent="onSubmit">
            <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Name</span>
                    <input type="text" class="form-control" v-model="name">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Company</span>
                    <input type="text" class="form-control" v-model="company">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group mb-1">
                    <span class="input-group-text">Price</span>
                    <input type="number" class="form-control" v-model="price" step="any">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="inputGroupSelect01">Variant</label>
                    </div>
                    <select class="form-control" id="product" v-model="variant">
                        <option value="0.25L">0.25 L</option>
                        <option value="0.5L">0.5 L</option>
                        <option value="1L">1 L</option>
                        <option value="3L">3 L</option>
                        <option value="4L">4 L</option>
                        <option value="10L">10 L</option>
                        <option value="20L">20 L</option>
                    </select>
                </div>
            </div>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">Pump</label>
                </div>
                <select class="form-control" id="pump" v-model="pump">
                    <option v-for="pump in allPumps" :key="pump.PumpID" :value="pump.PumpID">
                        {{ pump.PumpName }}
                    </option>
                </select>
            </div>
            <button type="submit" class="btn btn-primary mt-2">Add</button>
        </form>
    </div>

</template>
<script>
import { toastMixin } from '@/mixins/toastMixin.js';
export default {
    mixins: [toastMixin],
    data() {
        return {
            name:null,
            company:null,
            variant:null,
            price:0,
            products : [],
            allPumps: [],
            pump:null,
        };
    },
    created(){
        this.getAllPumps();
    },
    mounted() {
        document.title = 'Add Mobil Oil';
    },
    methods: {
        onSubmit() {
            const data = {
                tableName: 'mobiloils',
                data: {
                    name:this.name,
                    price:this.price,
                    company:this.company,
                    variant:this.variant,
                    PumpID:this.pump,
                },
            };

            this.axios.post(`${this.API_URL}insert.php`, data)
                .then(response => {
                    console.log(response.data);
                    this.successToast();

                    this.name = null;
                    this.price = 0;
                    this.company = null;
                    this.variant = null;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        async getAllPumps() {
            this.axios.get(`${this.API_URL}get_table_data.php`, {
                params: {
                    tableName: 'petrolpumps'
                }
            }).then((response) => {
                this.allPumps = response.data;
            }).catch((error) => {
                console.error('Error fetching Petrol Pumps:', error);
            });
        }

    }
}
</script>