<template>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-10 p-2">
                <h2> Expenses</h2>
            </div>

        </div>

        <div class="row hprint ">
            <div class="col-lg-6">
                <button @click="exportToPDF()" class="btn btn-danger ml-2">PDF</button>
            </div>
            <div class="row col-lg-4">
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="minDate">
                </div>
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="maxDate">
                </div>
            </div>
            <div class="col-lg-2 text-end">
                <button @click="dateSelected()" class="btn btn-secondary">Show Data</button>
            </div>
        </div>
        <div >
            <table class="table" id="myTable">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Expense Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(r, index) in records" :key="r.ReportID">
                        <td>{{ index }}</td>
                        <td>{{ formatDate(r.ReportDate) }}</td>
                        <th>{{ r.DailyExpense }}</th>
                        <td>{{ r.ExpenseDetail }}</td>
                    </tr>
    
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
import { toastMixin } from '@/mixins/toastMixin.js';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
export default {
    mixins: [toastMixin, PetrolPumpMixin, GlobalMixin],
    data() {
        return {
            records: [],
            pumpId: null,
            minDate: null,
            maxDate: null,
        };
    },

    mounted() {
        document.title = 'Expense Details';
    },
    created() {
        this.pumpId = this.$route.params.id;
        this.axios.get(`${this.API_URL}get_query.php`, {
            params: {
                query: `SELECT * FROM dailyreports
                WHERE PumpID = ${this.pumpId} ORDER BY ReportID DESC`,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            this.records = response.data;
            console.log(response.data);
        }).catch((error) => {
            if (error.response.status == 401) {
                localStorage.removeItem('UserID');
                localStorage.removeItem('expiryDate');
                localStorage.removeItem('userLoggedIn');
                localStorage.removeItem('token');
                this.$router.push('/login');
            }
        });
    },

    methods: {
        formatDate(dateString) {
            const formattedDate = new Date(dateString).toLocaleDateString('en-GB');
            return formattedDate;
        },
        async dateSelected() {
            const minDate = `${this.minDate}`;
            const maxDate = `${this.maxDate}`;
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query: `SELECT * FROM dailyreports WHERE PumpID = ${this.pumpId} AND ReportDate >= '${minDate}' AND ReportDate <= '${maxDate}' ;`
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.records = response.data;

            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            }
        },
    }
}
</script>@/mixins/GlobalMixin.js