<template>
    <div class="container">
        <header class="d-flex flex-wrap justify-content-center py-3 border-bottom">
            <a href="/"
                class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <img src="../../assets/rsf.png" alt="" height="50">
            </a>


            <ul class="nav nav-pills">

                <li class="nav-item">
                    <router-link to="/inventory" class="nav-link" exact>Inventory</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/dip-rod" class="nav-link" exact>Dip Rod</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/pricings" class="nav-link" exact>Pricing</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/mobil-oil" class="nav-link" exact>Mobil Oil</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/petrol-pumps" class="nav-link" exact>Petrol Pumps</router-link>
                    <!-- <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Petrol Pumps
                    </button> -->
                    <!-- <div class="dropdown-menu" aria-labelledby="petrolPumpsDropdown">
                        <router-link to="/petrol-pumps" class="dropdown-item">All Petrol Pumps</router-link>
                        <router-link to="/add-petrol-pumps" class="dropdown-item">Add Petrol Pumps</router-link>
                    </div> -->
                </li>

                <li class="nav-item">
                    <router-link to="/readings" class="nav-link">Readings</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/customers" class="nav-link">Customers</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/employees" class="nav-link">Employees</router-link>
                </li>
                <li class="nav-item">
                    <button class="nav-link" @click="logout">Logout</button>
                </li>
            </ul>

        </header>
    </div>
    
</template>

<script>
export default {
    
   
    mounted() {
        
        
    },
    methods: {
        async logout() {

            const response = await this.axios.get(`${this.API_URL}logout.php`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: {
                    user_id: localStorage.getItem('UserID')
                }
            });

            if (response) {
                localStorage.removeItem('UserID');
                localStorage.removeItem('expiryDate');
                localStorage.removeItem('userLoggedIn');
                localStorage.removeItem('token');
            }

            this.$router.push('/login');
        },
        
    },
};
</script>

<style lang="scss">
.floting-menu-icon {
    background-color: rgb(183, 7, 189);
    display: inline-block;
    border-radius: 5px 0px 0px 5px;
    position: fixed;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 30px;
    top: 100px;
    right: 0px;
    a{
        display: inline-block;
        padding: 5px 10px;
    }
    i {
        color: aliceblue;
    }
}

.floting-menu {
    background: linear-gradient(90deg, #C7C5F4, #776BCC) !important;
    position: fixed;
    top: 140px;
    right: 0;
    width: 300px;
    padding: 10px 20px 20px;
    border-radius: 10px 0px 0px 10px;
    z-index: 999;
}
</style>