<template>
    <div class="container mt-2 ">
        <div class="row align-items-center my-3">
            <div class="col-lg-6">
                <h2>Dip Rod Data</h2>
            </div>
            <div class="col-lg-6 text-right">
                <button class="btn btn-primary" @click="openModal('add')">Add Dip Rod</button>
            </div>
        </div>
    </div>

    <section class="container mt-2">
        <table class="table table-small">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Pump ID</th>
                    <th scope="col">T1 Diesel (MM)</th>
                    <th scope="col">T1 Diesel (L)</th>
                    <th scope="col">T1 Petrol (MM)</th>
                    <th scope="col">T1 Petrol (L)</th>
                    <th scope="col">T2 Diesel (MM)</th>
                    <th scope="col">T2 Diesel (L)</th>
                    <th scope="col">T2 Petrol (MM)</th>
                    <th scope="col">T2 Petrol (L)</th>
                    <th scope="col" v-if="this.userStore?.user?.UserType == 'super_admin'">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item.RecordId">
                    <th scope="row">{{ item.Date }}</th>
                    <td>{{ item.PumpID }}</td>
                    <td>{{ item.T1DieselMeasureInMM }}</td>
                    <td>{{ item.T1DieselInLiters }}</td>
                    <td>{{ item.T1PetrolMeasureInMM }}</td>
                    <td>{{ item.T1PetrolInLiters }}</td>
                    <td>{{ item.T2DieselMeasureInMM }}</td>
                    <td>{{ item.T2DieselInLiters }}</td>
                    <td>{{ item.T2PetrolMeasureInMM }}</td>
                    <td>{{ item.T2PetrolInLiters }}</td>
                    <td class="btn-group" role="group" v-if="this.userStore?.user?.UserType == 'super_admin'">
                        <button @click="openModal('edit', item)" class="btn btn-primary">Edit</button>
                        <button @click="deleteDipRod(item.RecordId)" class="btn btn-danger">Delete</button>
                    </td>
                </tr>

            </tbody>
        </table>
    </section>
    <ModalComponent :showModal="showModal.add" @close="closeModal('add')">
        <div v-if="modalType === 'add'" class="modal-content">
            <h2 class="mb-2">Add Dip Rod Data</h2>
            <form @submit.prevent="onSubmit">
                <div class="row">
                    <!-- <div class="col-lg-6">
                        <div class=" input-group mb-3">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="inputGroupSelect01">Pump</label>
                            </div>
                            <select class="form-control" id="pump" v-model="pump">
                                <option v-for="pump in pumpStore.pumps" :key="pump.PumpID" :value="pump.PumpID">
                                    {{ pump.PumpName }}
                                </option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Date</span>
                            <input type="date" class="form-control" v-model="selectDate">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <h3>Tank 1</h3>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Diesel In MM</span>
                            <input type="number" class="form-control" v-model="T1DieselMeasureInMM" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Diesel In Litres</span>
                            <input type="number" class="form-control" v-model="T1DieselInLiters" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Petrol In MM</span>
                            <input type="number" class="form-control" v-model="T1PetrolMeasureInMM" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Petrol In Litres</span>
                            <input type="number" class="form-control" v-model="T1PetrolInLiters" step="any">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <h3>Tank 2</h3>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Diesel In MM</span>
                            <input type="number" class="form-control" v-model="T2DieselMeasureInMM" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Diesel In Litres</span>
                            <input type="number" class="form-control" v-model="T2DieselInLiters" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Petrol In MM</span>
                            <input type="number" class="form-control" v-model="T2PetrolMeasureInMM" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Petrol In Litres</span>
                            <input type="number" class="form-control" v-model="T2PetrolInLiters" step="any">
                        </div>
                    </div>
                </div>


                <button type="submit" class="btn btn-primary mt-2">Add</button>
            </form>
        </div>
    </ModalComponent>

    <ModalComponent :showModal="showModal.edit" @close="closeModal('edit')">
        <div v-if="modalType === 'edit'" class="modal-content">
            <h2 class="mb-2">Edit Dip Rod Data</h2>
            <form @submit.prevent="onEdit">
                <div class="row">
                    <!-- <div class="col-lg-6">
                        <div class=" input-group mb-3">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="inputGroupSelect01">Pump</label>
                            </div>
                            <select class="form-control" id="pump" v-model="editdip.PumpID">
                                <option v-for="pump in pumpStore.pumps" :key="pump.PumpID" :value="pump.PumpID">
                                    {{ pump.PumpName }}
                                </option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Date</span>
                            <input type="date" class="form-control" v-model="editdip.Date">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <h3>Tank 1</h3>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Diesel In MM</span>
                            <input type="number" class="form-control" v-model="editdip.T1DieselMeasureInMM" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Diesel In Litres</span>
                            <input type="number" class="form-control" v-model="editdip.T1DieselInLiters" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Petrol In MM</span>
                            <input type="number" class="form-control" v-model="editdip.T1PetrolMeasureInMM" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Petrol In Litres</span>
                            <input type="number" class="form-control" v-model="editdip.T1PetrolInLiters" step="any">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <h3>Tank 2</h3>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Diesel In MM</span>
                            <input type="number" class="form-control" v-model="editdip.T2DieselMeasureInMM" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Diesel In Litres</span>
                            <input type="number" class="form-control" v-model="editdip.T2DieselInLiters" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Petrol In MM</span>
                            <input type="number" class="form-control" v-model="editdip.T2PetrolMeasureInMM" step="any">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-group mb-1">
                            <span class="input-group-text">Petrol In Litres</span>
                            <input type="number" class="form-control" v-model="editdip.T2PetrolInLiters" step="any">
                        </div>
                    </div>
                </div>


                <button type="submit" class="btn btn-primary mt-2">Add</button>
            </form>
        </div>
    </ModalComponent>

</template>
<script>
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
import { toastMixin } from '@/mixins/toastMixin.js';
// import { usePumpStore } from '@/store/PumpStore.js';
import ModalComponent from '@/components/ModalComponent.vue';
export default {
    mixins: [toastMixin, GlobalMixin],
    components: {
        ModalComponent,
    },
    data() {
        return {
            showModal: {
                add: false,
                edit: false
            },
            T1DieselMeasureInMM: 0,
            T1DieselInLiters: 0,
            T1PetrolMeasureInMM: 0,
            T1PetrolInLiters: 0,
            T2DieselMeasureInMM: 0,
            T2DieselInLiters: 0,
            T2PetrolMeasureInMM: 0,
            T2PetrolInLiters: 0,
            pump: null,
            selectDate: null,
            data: [],
            editdip:{},
        };
    },
    setup() {
        // // const pumpStore = usePumpStore()
        // const fetchData = async () => {
        //     await pumpStore.getPumps();
        // };
        // fetchData();
        // return { pumpStore }
    },
    mounted() {
        document.title = 'Add Diprod Data';
        this.getAlldata();
    },
    methods: {
        openModal(type, e = null) {
            this.modalType = type;
            this.showModal[type] = true;
            if (type === 'edit' && e) {
                console.log(e);
                this.editdip = { ...e };
            }
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        onSubmit() {
            const data = {
                tableName: 'diproddata',
                data: {
                    PumpID: this.globalPumpId,
                    Date: this.selectDate,
                    T1DieselMeasureInMM: this.T1DieselMeasureInMM,
                    T1DieselInLiters: this.T1DieselInLiters,
                    T1PetrolMeasureInMM: this.T1PetrolMeasureInMM,
                    T1PetrolInLiters: this.T1PetrolInLiters,
                    T2DieselMeasureInMM: this.T2DieselMeasureInMM,
                    T2DieselInLiters: this.T2DieselInLiters,
                    T2PetrolMeasureInMM: this.T2PetrolMeasureInMM,
                    T2PetrolInLiters: this.T2PetrolInLiters,
                },
            };

            this.axios.post(`${this.API_URL}insert.php`, data)
                .then(() => {
                    this.successToast();

                    this.PumpID = 0;
                    this.DieselMeasureInMM = 0;
                    this.DieselInLiters = 0;
                    this.PetrolMeasureInMM = 0;
                    this.PetrolInLiters = 0;
                    this.getAlldata();
                    this.closeModal('add')
                })
                .catch(error => {
                    console.error(error);
                });
        },

        async getAlldata() {
            let query = `SELECT * FROM diproddata WHERE PumpID = ${this.globalPumpId}`;
            this.data = await this.getRequest(query);
            console.log(this.data);
        },
        
        async deleteDipRod(id) {
            try {
                let query = `DELETE FROM diproddata WHERE RecordId = '${id}';`;
                await this.getRequest(query);
                this.getAlldata();
                this.deleteToast();
            } catch (error) {
                // Handle error
                console.error('Error deleting employee:', error);
            }
        },
        async onEdit() {
            
            let data = {
                PumpID: this.globalPumpId,
                Date: this.editdip.selectDate,
                T1DieselMeasureInMM: this.editdip.T1DieselMeasureInMM,
                T1DieselInLiters: this.editdip.T1DieselInLiters,
                T1PetrolMeasureInMM: this.editdip.T1PetrolMeasureInMM,
                T1PetrolInLiters: this.editdip.T1PetrolInLiters,
                T2DieselMeasureInMM: this.editdip.T2DieselMeasureInMM,
                T2DieselInLiters: this.editdip.T2DieselInLiters,
                T2PetrolMeasureInMM: this.editdip.T2PetrolMeasureInMM,
                T2PetrolInLiters: this.editdip.T2PetrolInLiters,
            };
            try {
                await this.updateRequest('diproddata', 'RecordId ', this.editdip.RecordId , data);
                this.getAlldata();
                this.successToast();
                this.closeModal('edit');
            } catch (error) {
                console.error('Error updating employee:', error);
            }
        },
    },
}
</script>