<template>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-10 p-2">
                <h2> Petrol Pumps </h2>
            </div>
            <div class="col-lg-2 text-right">
                <button @click="openModal('add')" class="btn btn-primary">Add New Pumps</button>
            </div>
            <ModalComponent :showModal="showModal.add" @close="closeModal('add')">
                <div v-if="modalType === 'add'" class="modal-content">
                    <form @submit.prevent="save_pump" class="row my-3">
                        <h4>Add New Pump</h4>
                        <div class="">

                            <div class="input-group mb-1">
                                <span class="input-group-text">Pump Name:</span>
                                <input class="form-control" aria-label="Pump Name" type="text" id="pumpName" v-model="formData.pumpName"
                                    required>
                            </div>

                            <div class="input-group mb-1">
                                <span class="input-group-text">Location:</span>
                                <input class="form-control" aria-label="Location" type="text" id="location" v-model="formData.location"
                                    required>
                            </div>

                            <div class="input-group mb-1">
                                <span class="input-group-text">Petrol Points:</span>
                                <input class="form-control" aria-label="Petrol Points" type="number" id="petrolPoints"
                                    v-model="formData.petrolPoints" required>
                            </div>

                            <div class="input-group mb-1">
                                <span class="input-group-text">Diesel Points:</span>
                                <input class="form-control" aria-label="Diesel Points" type="number" id="dieselPoints"
                                    v-model="formData.dieselPoints" required>
                            </div>
                            <button class="mt-4 btn btn-primary" type="submit">Save Petrol Pump</button>
                        </div>
                    </form>

                </div>
            </ModalComponent>
        </div>

       

        <table class="table" v-if="allPumps.length > 0">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Pump Name</th>
                    <th scope="col">Location</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(petrolpump, index) in allPumps" :key="petrolpump.PumpID">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ petrolpump.PumpName }}</td>
                    <td>{{ petrolpump.Location }}</td>
                    <td>
                        <div class="btn btn-primary btn-container">
                            <router-link class="text-white text-decoration-none"
                                :to="{ name: 'PetrolPumpDetail', params: { id: petrolpump.PumpID } }"> Go to Details
                            </router-link>
                        </div>
                        <div class="btn btn-primary btn-container">
                            <router-link class="text-white text-decoration-none"
                                :to="{ name: 'PetrolPumpReport', params: { id: petrolpump.PumpID } }"> Reports
                            </router-link>
                        </div>
                        <div class="btn btn-primary btn-container">
                            <router-link class="text-white text-decoration-none"
                                :to="{ name: 'CardsTransactions', params: { id: petrolpump.PumpID } }"> Cards
                                Transactions </router-link>
                        </div>
                        <div class="btn btn-primary btn-container">
                            <router-link class="text-white text-decoration-none"
                                :to="{ name: 'BankDeposits', params: { id: petrolpump.PumpID } }"> Bank Deposit
                            </router-link>
                        </div>
                        <div class="btn btn-primary btn-container">
                            <router-link class="text-white text-decoration-none"
                                :to="{ name: 'ExpenseDetails', params: { id: petrolpump.PumpID } }"> Expenses
                            </router-link>
                        </div>
                        <button class="btn btn-success btn-container" @click="openModal('edit', petrolpump)">Edit Pump</button>
                        <button class="btn btn-danger" v-if="this.userStore?.user?.UserType == 'super_admin'" @click="deletePump(petrolpump.PumpID)">Delete Pump</button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <ModalComponent :showModal="showModal.edit" @close="closeModal('edit')">
        <div v-if="modalType === 'edit'" class="modal-content">
            <form @submit.prevent="edit_pump" class="row my-3">
                <h4>Edit this Pump</h4>
                <div class="">

                    <div class="input-group mb-1">
                        <span class="input-group-text">Pump Name:</span>
                        <input class="form-control" aria-label="Pump Name" type="text" id="pumpName" v-model="editPump.PumpName"
                            required>
                    </div>

                    <div class="input-group mb-1">
                        <span class="input-group-text">Location:</span>
                        <input class="form-control" aria-label="Location" type="text" id="location" v-model="editPump.Location"
                            required>
                    </div>

                    <button class="mt-4 btn btn-primary" type="submit">Save Petrol Pump</button>
                </div>
            </form>

        </div>
    </ModalComponent>

</template>
<style>
.btn-container {
    margin-right: 15px;

    a {
        text-decoration: none;
    }
}
</style>
<script>
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
import { toastMixin } from '@/mixins/toastMixin.js';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';

import ModalComponent from '@/components/ModalComponent.vue';
export default {
    mixins: [toastMixin, PetrolPumpMixin, GlobalMixin],
    components: {
        ModalComponent,
    },
    data() {
        return {
            showModal: {
                add:false,
                edit: false
            },
            petrolpumps: [],
            formData: {
                pumpName: '',
                location: '',
                petrolPoints: null,
                dieselPoints: null
            },
            editPump: {},
            
        };
    },
    methods:{
        openModal(type, e = null) {
            this.modalType = type;
            this.showModal[type] = true;
            if (type === 'edit' && e) {
                console.log(e);
                this.editPump = { ...e };
            }
        },
        closeModal(type) {
            this.showModal[type] = false;
        },
        save_pump() {
            const apiUrl = `${this.API_URL}add_pump.php`;
            const requestData = {
                tableName: 'petrolpumps',
                data: this.formData,
            };

            this.axios.post(apiUrl, requestData).then(() => {
                
                this.successToast();
                this.getAllPumps();
                this.closeModal('add');
                
            }).catch((error) => {
                console.error(error);
            });
        },
        async edit_pump(){
            let data = {
                PumpName: this.editPump.PumpName,
                Location: this.editPump.Location,
            };
            try {
                await this.updateRequest('petrolpumps', 'PumpID', this.editPump.PumpID, data);
                this.getAllPumps();
                this.successToast();
                this.closeModal('edit');
            } catch (error) {
                console.error('Error updating employee:', error);
            }
        },
        async deletePump(PumpID) {
            try {
                const query =  [
                    {"table": "bankdeposits", "condition": `PumpID = ${PumpID}`},
                    {"table": "cardtransactions", "condition": `PumpID = ${PumpID}`},
                    {"table": "customers", "condition": `PumpID = ${PumpID}`},
                    {"table": "dailyreports", "condition": `PumpID = ${PumpID}`},
                    {"table": "diproddata", "condition": `PumpID = ${PumpID}`},
                    {"table": "employees", "condition": `PumpID = ${PumpID}`},
                    {"table": "expenses", "condition": `PumpID = ${PumpID}`},
                    {"table": "fillingpoints", "condition": `PumpID = ${PumpID}`},
                    {"table": "inventory", "condition": `PumpID = ${PumpID}`},
                    {"table": "mobiloils", "condition": `PumpID = ${PumpID}`},
                    {"table": "mobiloilsales", "condition": `PumpID = ${PumpID}`},
                    {"table": "mobiloilstock", "condition": `PumpID = ${PumpID}`},
                    {"table": "pricehistory", "condition": `PumpID = ${PumpID}`},
                    {"table": "readings", "condition": `PumpID = ${PumpID}`},
                    {"table": "petrolpumps", "condition": `PumpID = ${PumpID}`}
                ];
                
                await this.hardDeleteRequest(query);
                this.getAllPumps();
                this.deleteToast();
            } catch (error) {
                // Handle error
                console.error('Error deleting employee:', error);
            }
        }
    }
};
</script>