// router/index.js
import { createRouter, createWebHistory } from "vue-router";

import LoginComponent from "@/pages/auth/LoginComponent.vue";
import PetrolPumpsList from "@/pages/petrolpumps/PetrolPumpsList.vue";
import PetrolPumpDetail from "@/pages/petrolpumps/PetrolPumpDetail.vue";
import AddMobilOil from "@/pages/MobilOil/AddMobilOil.vue";
import StockRecord from "@/pages/MobilOil/StockRecord.vue";

// import UsersListComponentVue from "@/pages/users/UsersListComponent.vue";

import NotFound from "@/pages/NotFound.vue"
import BankDeposits from "@/pages/BankDeposits.vue"
import PricingComponent from "@/pages/PricingComponent.vue"
import DashboardComponentVue from "@/pages/DashboardComponent.vue";
import InventoryComponent from "@/pages/InventoryComponent.vue";
import MobiloilComponent from "@/pages/MobiloilComponent.vue";
import ExpenseDetails from "@/pages/petrolpumps/ExpenseDetails.vue";
import PetrolPumpReportVue from "@/pages/petrolpumps/PetrolPumpReport.vue";
import NozelReadings from "@/pages/petrolpumps/NozelReadings.vue";
import CustomersComponentVue from "./pages/CustomersComponent.vue";
import CustomerDetails from "./pages/customers/CustomerDetails.vue";
import EmployeesComponent from "./pages/Employees/EmployeesComponent.vue";
import EmployeeDetails from "./pages/Employees/EmployeeDetails.vue";
import DipRodComponent from "./pages/DipRod.vue";
import CardsTransactions from "./pages/CardsTransactions.vue";

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes: [
        { path: "/", redirect: '/dashboard', name:'Default', meta: { requiresAuth: true } },
        { path: "/dashboard", component: DashboardComponentVue, name: 'DashboardComponentVue', meta: { requiresAuth: true } },
        { path: "/inventory", component: InventoryComponent, name: 'InventoryComponent', meta: { requiresAuth: true } },
        { path: "/dip-rod", component: DipRodComponent, name: 'DipRodComponent', meta: { requiresAuth: true } },
        { path: "/pricings", component: PricingComponent, name: 'PricingComponent', meta: { requiresAuth: true } },
        // { path: "/users", component: UsersListComponentVue, name: 'UsersListComponentVue', meta: { requiresAuth: true } },
        { path: "/customers", component: CustomersComponentVue, name: 'CustomersComponentVue', meta: { requiresAuth: true } },
        { path: "/customer-detail/:id", component: CustomerDetails, name: 'CustomerDetails', meta: { requiresAuth: true } },

        { path: "/employees", component: EmployeesComponent, name: 'EmployeesComponent', meta: { requiresAuth: true } },
        { path: "/employee-details/:id", component: EmployeeDetails, name: 'EmployeesDetails', meta: { requiresAuth: true } },
        { path: "/mobil-oil", component: MobiloilComponent, name: 'MobiloilComponent', meta: { requiresAuth: true } },
        { path: "/add-product", component: AddMobilOil, name: 'AddMobilOil', meta: { requiresAuth: true } },
        { path: "/product-stock", component: StockRecord, name: 'StockRecord', meta: { requiresAuth: true } },
        { path: "/petrol-pumps", component: PetrolPumpsList, name:'PetrolPumpsList', meta: { requiresAuth: true } },
        { path: "/petrol-pump/:id", component: PetrolPumpDetail, name:'PetrolPumpDetail', meta: { requiresAuth: true } },
        { path: "/petrol-pump-reports/:id", component: PetrolPumpReportVue, name:'PetrolPumpReport', meta: { requiresAuth: true } },
        { path: "/readings", component: NozelReadings, name:'NozelReadings', meta: { requiresAuth: true } },
        { path: "/deposits/:id", component: BankDeposits, name:'BankDeposits', meta: { requiresAuth: true } },
        { path: "/expenses/:id", component: ExpenseDetails, name:'ExpenseDetails', meta: { requiresAuth: true } },
        { path: "/card-transactions/:id", component: CardsTransactions, name:'CardsTransactions', meta: { requiresAuth: true } },
        
        { path: "/login", component: LoginComponent, name:'login' },
        { path: "/:notFound(.*)", component: NotFound },
    ],
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active', 
});
router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('userLoggedIn') == 'true';
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next('/login'); 
    } else {
        next();
    }
});

export default router;
