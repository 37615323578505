
<template>
    <div class="container">
        <section class="hprint">
            <h3 class="text-left mb-3 bg-secondary text-white rounded p-2">{{ pumpDetail.PumpName }} - {{ pumpDetail.Location
            }}</h3>
        </section>

        <div class="row hprint content-align-center">
            <div class="col-lg-6">
                <button @click="printTable()" class="btn btn-success" style="margin-right: 10px;">Print Table</button>
                <button @click="exportToPDF()" class="btn btn-danger ml-2">PDF</button>
            </div>
            <div class="row col-lg-4">

                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="minDate">
                </div>
                <div class="col-lg-6">
                    <input type="date" name="" id="" v-model="maxDate">
                </div>

            </div>
            <div class="col-lg-2 text-end">
                <button @click="dateSelected()" class="btn btn-primary">Show Data</button>
            </div>
        </div>

        <!-- <button @click="successToast()">Test Toast</button> -->
        <table class="table table-small" ref="dataTable" id="myTable" v-if="reportArr.length > 0">
            <thead>
                <tr>
                    <th scope="col">Total Cash</th>
                    <th scope="col">Bank Deposited</th>
                    <th scope="col">Total Exp</th>
                    <th scope="col">Pump Rent</th>
                    <th scope="col">Dip Comparison Diesel</th>
                    <th scope="col">TT Diesel</th>
                    <th scope="col">Diesel T.Price</th>
                    <th scope="col">Diesel Price</th>
                    <th scope="col">Diesel Sold</th>
                    <th scope="col">Dip Stock</th>
                    <th scope="col">Received Diesel</th>

                    <th scope="col">Dip Comparison Petrol</th>
                    <th scope="col">TT Petrol</th>
                    <th scope="col">Petrol T.Price</th>
                    <th scope="col">Petrol Price</th>
                    <th scope="col">Petrol Sold</th>
                    <th scope="col">Dip Stock</th>
                    <th scope="col">Received Petrol</th>

                    <th scope="col">Report Date</th>
                    <th scope="col"  v-if="this.userStore?.user?.UserType == 'super_admin'">DD</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="report in reportArr" :key="report.ReportID">
                    <td scope="col">{{ report.InCash }}</td>
                    <td scope="col">{{ report.BankDepositAmount }}</td>
                    <td scope="col">{{ parseFloat(report.StaffExpense) + parseFloat(report.DailyExpense) }}</td>
                    <td scope="col">{{ parseFloat(report.PumpRent) }}</td>
                    <td scope="col">
                        {{ ((parseFloat(report.T1DieselInLiters) + parseFloat(report.T2DieselInLiters)) -
                            parseFloat(report.ClosingDieselQty)).toFixed(2) }}
                    </td>
                    <td scope="col">{{ report.TTDiesel }}</td>
                    <td scope="col">{{ (report.DieselSold * report.DieselPrice).toFixed(2) }}</td>
                    <td scope="col">{{ report.DieselPrice }}</td>
                    <td scope="col">{{ report.DieselSold }}</td>
                    <td scope="col">{{ parseFloat(report.T1DieselInLiters) + parseFloat(report.T2DieselInLiters) }}</td>
                    <td scope="col">{{ report.TotalReceivedDieselQty }}</td>

                    <td scope="col">{{ parseFloat((parseFloat(report.T1PetrolInLiters) + parseFloat(report.T2PetrolInLiters)) -
                        (report.ClosingPetrolQty)).toFixed(2) }}</td>
                    <td scope="col">{{ report.TTPetrol }}</td>
                    <td scope="col">{{ (report.PetrolSold * report.PetrolPrice).toFixed(2) }}</td>
                    <td scope="col">{{ report.PetrolPrice }}</td>
                    <td scope="col">{{ report.PetrolSold }}</td>
                    <td scope="col">{{ parseFloat(report.T1PetrolInLiters) + parseFloat(report.T2PetrolInLiters) }}</td>
                    <td scope="col">{{ report.TotalReceivedPetrolQty }}</td>

                    <td scope="col">{{ formatDate(report.ReportDate) }}</td>

                    <td v-if="this.userStore?.user?.UserType == 'super_admin'">
                        <button @click="deleteReport(report.ReportDate)" class="btn btn-danger">Delete</button>
                    </td>
                </tr>
            </tbody>

            <tfoot>
                <tr>

                    <td scope="col">{{ inCashTotal }}</td>
                    <td scope="col">{{ bankDepositTotal }}</td>
                    <td scope="col">{{ totalExpense }}</td>
                    <td scope="col"></td>
                    <td scope="col">{{ dipComparisonDtotal }}</td>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td scope="col">{{ totalDieselSold }}</td>
                    <td scope="col"></td>
                    <td scope="col">{{ totalReceivedDiesel }}</td>

                    <td scope="col">{{ dipComparisonPtotal }}</td>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td scope="col">{{ totalPetrolSold }}</td>
                    <td scope="col"></td>
                    <td scope="col">{{ totalReceivedPetrol }}</td>

                    <td scope="col"></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>

// import $ from 'jquery';
import jsPDF from 'jspdf';
// import 'jspdf-autotable';

import { toastMixin } from '@/mixins/toastMixin.js';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
import { GlobalMixin } from '@/mixins/GlobalMixin.js';
export default {
    mixins: [PetrolPumpMixin, toastMixin, GlobalMixin],
    // components: { DataTable },
    data() {
        return {
            pumpId: null,
            reportArr: [],
            minDate: null,
            maxDate: null,
        }
    },
    async mounted() {
        document.title = "RSF - Reports";
        this.fetchPumpData();
        await this.reports();

    },
    created() {
        this.pumpId = this.$route.params.id;
    },

    methods: {
        printTable() {
            // // Get the table element by ID
            const table = document.getElementById('myTable');

            // Create a separate print-friendly table by getting its HTML content
            const tableContent = table.outerHTML;

            // Open a new window/tab and write the table content
            const printWindow = window.open('', '_blank');
            printWindow.document.write('<style>td{text-align:center;font-size:12px;}</style>' + tableContent);
            printWindow.document.close();

            // window.print()
        },


        exportToPDF() {
            // Initialize jsPDF
            const doc = new jsPDF({
                orientation: 'landscape'
            });

            const table = document.getElementById('myTable');

            doc.autoTable({
                html: table,
                styles: { fillColor: [255, 255, 255], fontSize: 6, textColor: [0, 0, 0] },
            });
            const currentDate = new Date();

            const day = String(currentDate.getDate()).padStart(2, '0');
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = currentDate.getFullYear();

            const formattedDate = `${day}/${month}/${year}`;

            doc.save(this.pumpId + '(' + formattedDate + ')');
        },

        async dateSelected() {
            const minDate = `${this.minDate} 00:00:00`;
            const maxDate = `${this.maxDate} 23:59:59`;
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {
                        query:`SELECT 
                            dr.ReportID,
                            dr.PumpID,
                            dr.ReportDate,
                            dr.DieselSold,
                            dr.PetrolSold,
                            dr.PetrolPrice,
                            dr.DieselPrice,
                            dr.MobilOilSold,
                            dr.StaffExpense,
                            dr.DailyExpense,
                            dr.InCash,
                            dr.CreditAmount,
                            dr.CardTransactions,
                            dr.BankDepositAmount,
                            dr.BankDepositAccount,
                            dr.TTDiesel,
                            dr.TTPetrol,
                            COALESCE(SUM(inv.ReceivedPetrolQty), 0) AS TotalReceivedPetrolQty,
                            COALESCE(SUM(inv.ReceivedDieselQty), 0) AS TotalReceivedDieselQty,
                            inv2.ClosingPetrolQty,
                            inv2.ClosingDieselQty,
                            inv2.ID,
                            inv.InventoryDate,
                            dd.T1DieselMeasureInMM,
                            dd.T1DieselInLiters,
                            dd.T1PetrolMeasureInMM,
                            dd.T1PetrolInLiters,
                            dd.T2DieselMeasureInMM,
                            dd.T2DieselInLiters,
                            dd.T2PetrolMeasureInMM,
                            dd.T2PetrolInLiters
                        FROM (
                            SELECT 
                                ReportID,
                                PumpID,
                                DATE(ReportDate) AS ReportDate,
                                DieselSold,
                                PetrolSold,
                                PetrolPrice,
                                DieselPrice,
                                MobilOilSold,
                                StaffExpense,
                                DailyExpense,
                                InCash,
                                CreditAmount,
                                CardTransactions,
                                BankDepositAmount,
                                BankDepositAccount,
                                TTDiesel,
                                TTPetrol
                            FROM dailyreports
                            WHERE PumpID = ${this.pumpId}
                        ) dr
                        LEFT JOIN (
                            SELECT 
                                PumpID,
                                Date,
                                SUM(ReceivedPetrolQty) AS ReceivedPetrolQty,
                                SUM(ReceivedDieselQty) AS ReceivedDieselQty,
                                MAX(Date) AS InventoryDate
                            FROM inventory
                            WHERE PumpID = ${this.pumpId}
                            GROUP BY PumpID, Date
                        ) inv ON inv.PumpID = dr.PumpID AND DATE(dr.ReportDate) = DATE(inv.Date)
                        LEFT JOIN (
                            SELECT 
                                PumpID,
                                Date,
                                ClosingDieselQty,
                                ClosingPetrolQty,
                                ID
                            FROM inventory
                            WHERE PumpID = ${this.pumpId}
                            AND (Date, ID) IN (
                                SELECT Date, MAX(ID) AS MaxID
                                FROM inventory
                                WHERE PumpID = ${this.pumpId}
                                GROUP BY Date
                            )
                        ) inv2 ON inv2.PumpID = dr.PumpID AND DATE(dr.ReportDate) = DATE(inv2.Date)
                        LEFT JOIN (
                            SELECT 
                                PumpID,
                                Date,
                                T1DieselMeasureInMM,
                                T1DieselInLiters,
                                T1PetrolMeasureInMM,
                                T1PetrolInLiters,
                                T2DieselMeasureInMM,
                                T2DieselInLiters,
                                T2PetrolMeasureInMM,
                                T2PetrolInLiters
                            FROM diproddata
                            WHERE PumpID = ${this.pumpId}
                            GROUP BY PumpID, Date
                        ) dd ON dr.PumpID = dd.PumpID AND DATE(dr.ReportDate) = DATE(dd.Date)
                        WHERE dr.PumpID = ${this.pumpId}  AND dr.ReportDate >= '${minDate}' AND dr.ReportDate <= '${maxDate}' 
                        GROUP BY dr.ReportID, dr.PumpID, dr.ReportDate, dr.DieselSold, dr.PetrolSold, dr.PetrolPrice, dr.DieselPrice, dr.MobilOilSold, dr.StaffExpense, dr.DailyExpense, dr.InCash, dr.CreditAmount, dr.CardTransactions, dr.BankDepositAmount, dr.BankDepositAccount, dr.TTDiesel, dr.TTPetrol, inv2.ClosingPetrolQty, inv2.ClosingDieselQty,inv2.ID, inv.InventoryDate, dd.T1DieselMeasureInMM, dd.T1DieselInLiters, dd.T1PetrolMeasureInMM, dd.T1PetrolInLiters, dd.T2DieselMeasureInMM, dd.T2DieselInLiters, dd.T2PetrolMeasureInMM, dd.T2PetrolInLiters
                        ORDER BY dr.ReportDate;`
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.reportArr = response.data;

            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            }
        },
        async reports() {
            try {
                const response = await this.axios.get(`${this.API_URL}get_query.php`, {
                    params: {

                        query: `SELECT 
                            dr.ReportID,
                            dr.PumpID,
                            dr.ReportDate,
                            dr.DieselSold,
                            dr.PetrolSold,
                            dr.PetrolPrice,
                            dr.DieselPrice,
                            dr.MobilOilSold,
                            dr.StaffExpense,
                            dr.DailyExpense,
                            dr.InCash,
                            dr.CreditAmount,
                            dr.CardTransactions,
                            dr.BankDepositAmount,
                            dr.BankDepositAccount,
                            dr.TTDiesel,
                            dr.TTPetrol,
                            dr.PumpRent,
                            COALESCE(SUM(inv.ReceivedPetrolQty), 0) AS TotalReceivedPetrolQty,
                            COALESCE(SUM(inv.ReceivedDieselQty), 0) AS TotalReceivedDieselQty,
                            inv2.ClosingPetrolQty,
                            inv2.ClosingDieselQty,
                            inv2.ID,
                            inv.InventoryDate,
                            dd.T1DieselMeasureInMM,
                            dd.T1DieselInLiters,
                            dd.T1PetrolMeasureInMM,
                            dd.T1PetrolInLiters,
                            dd.T2DieselMeasureInMM,
                            dd.T2DieselInLiters,
                            dd.T2PetrolMeasureInMM,
                            dd.T2PetrolInLiters
                        FROM (
                            SELECT 
                                ReportID,
                                PumpID,
                                DATE(ReportDate) AS ReportDate,
                                DieselSold,
                                PetrolSold,
                                PetrolPrice,
                                DieselPrice,
                                MobilOilSold,
                                StaffExpense,
                                DailyExpense,
                                InCash,
                                CreditAmount,
                                CardTransactions,
                                BankDepositAmount,
                                BankDepositAccount,
                                TTDiesel,
                                PumpRent,
                                TTPetrol
                            FROM dailyreports
                            WHERE PumpID = ${this.pumpId}
                        ) dr
                        LEFT JOIN (
                            SELECT 
                                PumpID,
                                Date,
                                SUM(ReceivedPetrolQty) AS ReceivedPetrolQty,
                                SUM(ReceivedDieselQty) AS ReceivedDieselQty,
                                MAX(Date) AS InventoryDate
                            FROM inventory
                            WHERE PumpID = ${this.pumpId}
                            GROUP BY PumpID, Date
                        ) inv ON inv.PumpID = dr.PumpID AND DATE(dr.ReportDate) = DATE(inv.Date)
                        LEFT JOIN (
                            SELECT 
                                PumpID,
                                Date,
                                ClosingDieselQty,
                                ClosingPetrolQty,
                                ID
                            FROM inventory
                            WHERE PumpID = ${this.pumpId}
                            AND (Date, ID) IN (
                                SELECT Date, MAX(ID) AS MaxID
                                FROM inventory
                                WHERE PumpID = ${this.pumpId}
                                GROUP BY Date
                            )
                        ) inv2 ON inv2.PumpID = dr.PumpID AND DATE(dr.ReportDate) = DATE(inv2.Date)
                        LEFT JOIN (
                            SELECT 
                                PumpID,
                                Date,
                                T1DieselMeasureInMM,
                                T1DieselInLiters,
                                T1PetrolMeasureInMM,
                                T1PetrolInLiters,
                                T2DieselMeasureInMM,
                                T2DieselInLiters,
                                T2PetrolMeasureInMM,
                                T2PetrolInLiters
                            FROM diproddata
                            WHERE PumpID = ${this.pumpId}
                            GROUP BY PumpID, Date
                        ) dd ON dr.PumpID = dd.PumpID AND DATE(dr.ReportDate) = DATE(dd.Date)
                        WHERE dr.PumpID = ${this.pumpId}
                        GROUP BY dr.ReportID, dr.PumpID, dr.ReportDate, dr.DieselSold, dr.PetrolSold, dr.PetrolPrice, dr.DieselPrice, dr.MobilOilSold, dr.StaffExpense, dr.DailyExpense, dr.InCash, dr.CreditAmount, dr.CardTransactions, dr.BankDepositAmount, dr.BankDepositAccount, dr.TTDiesel, dr.TTPetrol, dr.PumpRent, inv2.ClosingPetrolQty, inv2.ClosingDieselQty,inv2.ID, inv.InventoryDate, dd.T1DieselMeasureInMM, dd.T1DieselInLiters, dd.T1PetrolMeasureInMM, dd.T1PetrolInLiters, dd.T2DieselMeasureInMM, dd.T2DieselInLiters, dd.T2PetrolMeasureInMM, dd.T2PetrolInLiters
                        ORDER BY dr.ReportDate;`
                    },
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.reportArr = response.data;
            } catch (error) {
                this.failedToast();
                if (error.response.status == 401) {
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('expiryDate');
                    localStorage.removeItem('userLoggedIn');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
            }
        },
        formatDate(dateString) {
            const formattedDate = new Date(dateString).toLocaleDateString('en-GB');
            return formattedDate;
        },
        async deleteReport(date) {
            console.log(date);
            let PumpID = this.pumpId;
            try {
                const query =  [
                    {"table": "wages", "condition": `Date = '${date}' AND PumpID = '${PumpID}'`},
                    {"table": "bankdeposits", "condition": `date = '${date}' AND PumpID = '${PumpID}'`},
                    {"table": "cardtransactions", "condition": `Date = '${date}' AND PumpID = '${PumpID}'`},
                    {"table": "expenses", "condition": `ExpenseDate = '${date}' AND PumpID = '${PumpID}'`},
                    {"table": "mobiloilsales", "condition": `Date = '${date}' AND PumpID = '${PumpID}'`},
                    {"table": "readings", "condition": `ReadingDate = '${date}' AND PumpID = '${PumpID}'`},
                    {"table": "transactionhistory", "condition": `Date = '${date}' AND PumpID = '${PumpID}'`},

                    {"table": "dailyreports", "condition": `ReportDate = '${date}' AND PumpID = '${PumpID}'`},
                ];
                
                await this.hardDeleteRequest(query);
                this.reports();
            } catch (error) {
                // Handle error
                console.error('Error deleting employee:', error);
            }
        },
        // deleteReport(date){
        //     console.log(date);
        // }

    },
    computed: {
        inCashTotal() {
            let t = 0;
            this.reportArr.forEach(r => {
                t = t + parseFloat(r.InCash);
            });
            return t;
        },
        bankDepositTotal() {
            let t = 0;
            // console.log(this.reportArr);
            this.reportArr.forEach(r => {
                t = t + parseFloat(r.BankDepositAmount);
            });
            return t;
        },
        totalReceivedDiesel() {
            let t = 0;
            this.reportArr.forEach(r => {
                t = t + parseFloat(r.TotalReceivedDieselQty);
            });
            return t;
        },
        totalReceivedPetrol() {
            let t = 0;
            this.reportArr.forEach(r => {
                t = t + parseFloat(r.TotalReceivedPetrolQty);
            });
            return t;
        },
        totalPetrolSold(){
            let t = 0;
            // console.log(this.reportArr);
            this.reportArr.forEach(r => {
                t = t + parseFloat(r.PetrolSold);
            });
            return t;
        },
        totalDieselSold(){
            let t = 0;
            this.reportArr.forEach(r => {
                t = t + parseFloat(r.DieselSold);
            });
            return t;
        },
        totalExpense() {
            let t = 0;
            this.reportArr.forEach(r => {
                t = t + parseFloat(r.StaffExpense) + parseFloat(r.DailyExpense);
            });
            return t;
        },
        dipComparisonDtotal() {
            // ((parseFloat(report.T1DieselInLiters) + parseFloat(report.T2DieselInLiters)) -
            //         parseFloat(report.ClosingDieselQty)).toFixed(2)
            let t = 0;
            this.reportArr.forEach(r => {
                t = t + ((parseFloat(r.T1DieselInLiters) + parseFloat(r.T2DieselInLiters)) -
                    parseFloat(r.ClosingDieselQty));
            });
            return t.toFixed(2);
        },
        dipComparisonPtotal() {
            let t = 0;
            this.reportArr.forEach(r => {
                t = t + parseFloat((parseFloat(r.T1PetrolInLiters) + parseFloat(r.T2PetrolInLiters)) - (r.ClosingPetrolQty));
            });
            return t.toFixed(2);
        },
    }
}
</script>
<style scoped>
.table thead th {
    font-size: 13px;

}

.table tbody td {
    font-size: 14px;
}

.dt-buttons {
    float: none !important;
    width: 100%;
}

tfoot tr td {
    font-style: italic;
}

@media print {
    #myTable {
        font-family: sans-serif;
        font-size: 12px;
    }

    #myTable td {
        font-size: 12px;
    }

}
</style>